import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import {
  getDashboardFailedPayments,
  setDashboardFailedPaymentsData,
  setDashboardFailedPaymentsError,
  setDashboardFailedPaymentsLoading,
} from './dashboardFailedPayments-slice';

const { get } = api;

function* getDashboardFailedPaymentsSagaListener(action: any) {
  try {
    yield put(setDashboardFailedPaymentsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.STRIPE_OVERDUE_INVOICES, action.payload);
    yield put(setDashboardFailedPaymentsData(res.data));
  } catch (error) {
    yield put(setDashboardFailedPaymentsError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardFailedPaymentsSaga(): Generator<StrictEffect> {
  yield takeEvery(getDashboardFailedPayments.type, getDashboardFailedPaymentsSagaListener);
}

export default dashboardFailedPaymentsSaga;
