import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import { ListResponse } from 'types';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { InboxResponse } from '../types';
import { getInbox, fetchUnreadCounter, setInboxData, setInboxError, setInboxLoading, setUnreadCounter } from './inbox-slice';

const { get } = api;

function* getInboxSagaListener(action: any) {
  try {
    yield put(setInboxLoading());
    const res: AxiosResponse<ListResponse<InboxResponse>> = yield call(get, apiRoutes.INBOX, action.payload);
    yield put(setInboxData(res.data));
  } catch (error) {
    yield put(setInboxError(error));
    asyncErrorHandler(error);
  }
}

function* fetchUnreadCounterSagaListener() {
  try {
    const response: AxiosResponse = yield call(get, `${apiRoutes.MENTIONS}/count`);

    yield put(setUnreadCounter(response.data.data.inbox_unread));
  } catch (error) {
    //
  }
}

function* inboxSaga(): Generator<StrictEffect> {
  yield takeLatest(getInbox.type, getInboxSagaListener);
  yield takeLatest(fetchUnreadCounter.type, fetchUnreadCounterSagaListener);
}

export default inboxSaga;
