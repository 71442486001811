/* eslint-disable @typescript-eslint/dot-notation */
import { Col, Dropdown, Empty, Input, Row, Space, Typography } from 'antd';
import StatusTag from 'components/StatusTag';
import SimpleBar from 'simplebar-react';

import apiRoutes from 'config/apiRoute';
import { FC, useEffect, useState } from 'react';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { convertSnakeCaseToTitleCase, includesInObject } from 'utils/string.utils';
import { CloseCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import appRoutes from 'config/appRoutes';
import axios from 'axios';

import styles from './GlobalSearch.module.less';

interface GlobalSearchProps {}

const { Text, Title } = Typography;
const GlobalSearch: FC<GlobalSearchProps> = () => {
  const [state, setState] = useState({ loading: false, data: null as any, search: null as any, visible: false });

  const { loading, data, search, visible } = state;
  const filteredData: any = {};
  Object.keys(data ?? {})?.forEach((el: any) => {
    filteredData[el] = data?.[el]?.filter((item: any) => {
      return includesInObject(search, item);
    });
  });
  const hasData = Object.keys(data ?? {})?.find((el: any) => data?.[el]?.length !== 0);

  const history = useHistory();

  const toggleVisible = () => {
    setState((prevState) => ({ ...prevState, visible: !prevState.visible }));
  };

  useEffect(() => {
    const abort = new AbortController();

    const fetchData = async () => {
      try {
        setState((prevState: any) => ({ ...prevState, loading: true }));

        const res = await apiRequests.get(
          `${apiRoutes.GLOBAL_SEARCH}`,
          {
            search_term: search,
          },
          {},
          abort,
        );

        setState((prevState: any) => ({ ...prevState, loading: false, data: res?.data?.data }));
      } catch (error) {
        if (!axios.isCancel(error)) {
          asyncErrorHandler(error);
          setState((prevState: any) => ({ ...prevState, loading: false }));
        }
      }
    };

    fetchData();

    return () => {
      abort.abort();
    };
  }, [search]);

  return (
    <Dropdown
      visible={visible}
      overlay={
        <div className={styles.overlay}>
          {!hasData ? (
            <div style={{ padding: 30 }}>
              <Empty />
            </div>
          ) : (
            <>
              <SimpleBar style={{ maxHeight: 400 }}>
                {Object.keys(filteredData ?? {}).map((type: string) => {
                  const parsedData = filteredData?.[type];

                  if (parsedData?.length === 0) {
                    return null;
                  }

                  return (
                    <div className={styles.item} key={type}>
                      <Title level={5} className="mb-m">
                        {convertSnakeCaseToTitleCase(type)}
                      </Title>
                      {parsedData?.map((item: any, key: any) => {
                        return (
                          <div
                            onClick={() => {
                              if (type === 'projects') {
                                history.push(`${appRoutes.PROJECTS}/${item?.uuid}`);
                              } else if (type === 'tasks') {
                                history.push(`${appRoutes.TASKS}?uuid=${item?.task_uuid}`);
                              } else if (type === 'people') {
                                history.push(`${appRoutes.CONTACTS}/${item?.uuid}/edit`);
                              } else if (type === 'cms') {
                                history.push(`${appRoutes.CONTENT}/${item?.uuid}/edit`);
                              } else if (type === 'forms') {
                                history.push(`${appRoutes.FORMS}/${item?.uuid}`);
                              } else if (type === 'clients') {
                                history.push(`${appRoutes.CLIENTS}/${item?.uuid}`);
                              } else {
                                console.log(type);
                              }
                            }}
                            className={styles?.data}
                            key={key}
                          >
                            <Row wrap={false} justify="space-between" gutter={10}>
                              <Col span={item?.status || item?.task_status ? 18 : 24}>
                                <Text ellipsis>{item?.business_name ?? item?.title ?? item?.name ?? item?.task_title}</Text>
                              </Col>
                              {(item?.status || item?.task_status) && (
                                <Col>
                                  <StatusTag value={item?.status ?? item?.task_status} />
                                </Col>
                              )}
                            </Row>
                            {(type === 'projects' || type === 'tasks') && (
                              <Row>
                                <Col span={24}>
                                  <Text style={{ fontSize: 12 }} className="primary" ellipsis>
                                    {item?.customer_business_name}
                                  </Text>
                                </Col>
                              </Row>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </SimpleBar>
            </>
          )}
        </div>
      }
    >
      <div className={styles.inputWrapper}>
        <Input
          placeholder="Search anything"
          value={search}
          onFocus={toggleVisible}
          onBlur={() => {
            setTimeout(() => toggleVisible(), 300);
          }}
          onChange={(e) => {
            setState((prevState) => ({ ...prevState, search: e.target.value }));
          }}
          size="large"
          className={styles.input}
          suffix={
            <Space>
              <Loading3QuartersOutlined style={{ visibility: loading ? 'visible' : 'hidden' }} spin size={20} />
              {search && (
                <CloseCircleOutlined
                  className="pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setState((prevState) => ({ ...prevState, search: undefined }));
                  }}
                />
              )}
            </Space>
          }
        />
      </div>
    </Dropdown>
  );
};

export default GlobalSearch;
