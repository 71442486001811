import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStopwatch } from 'react-timer-hook';
import { Form } from 'antd';
import moment from 'moment';
import { getActiveLogs, setActiveLogsActiveData } from 'store/container/activeLogs/activeLogs-slice';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import {
  convertDaysHrsMinsToMins,
  convertHrsMinsToMins,
  convertMinsToHrsMins,
  getDateDifferenceInMinutes,
  getOffsetTimestamp,
} from 'utils/string.utils';
import useWebSocket from 'utils/useWebSocket';

// @ts-ignore
const localRandomUuid: string = window.crypto.randomUUID();

const useTimer = (
  task_id: string | undefined,
  globalActiveTimer: boolean,
  timerContent: any,
  onAddEntry: any,
  onClose: () => void,
) => {
  // fallback state
  let fallbackTimer: any;
  let fallbackStatus: any;

  const user = useSelector((store: any) => store.auth.user);

  const { minutes, hours, days, isRunning, start, pause, reset } = useStopwatch({ autoStart: false });
  const [state, setState] = useState({
    loading: false,
    saving: false,
    current: null as any,
    user: null as any,
    error: false,
    totalTime: 0,
    taskTotalTime: 0,
  });

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { current } = state;
  const activeLogs = useSelector((globalState: any) => globalState.activeLogs);

  const updateTimer = async () => {
    try {
      const time = convertHrsMinsToMins(form.getFieldValue('time'));
      const date = moment(form.getFieldValue('date')).format('YYYY-MM-DD');
      const description = form.getFieldValue('description');

      if ((!current?.uuid && time === 0) || current?.minutes === time) {
        return;
      }

      setState((prevState) => ({ ...prevState, saving: true }));

      if (current?.uuid) {
        await apiRequests.put(`${apiRoutes.TIME_LOG}/${current.uuid}`, {
          started_at: current.started_at ? moment(Date.now()).format('YYYY-MM-DD HH:mm:ss') : '',
          minutes: time,
          date,
          description: description ?? '',
          ref_token: localRandomUuid,
        });
        setState((prevState) => ({ ...prevState, saving: false }));
      } else {
        const res = await apiRequests.post(`${apiRoutes.TIME_LOG}`, {
          task_id,
          user_id: user.uuid,
          date,
          started_at: '',
          description: description ?? '',
          status: 'paused',
          minutes: time,
          ref_token: localRandomUuid,
        });
        setState((prevState) => ({ ...prevState, saving: false, current: res.data.data }));
      }

      if (onAddEntry) {
        onAddEntry();
      }

      dispatch(getActiveLogs());
    } catch (error) {
      // reset(getOffsetTimestamp(fallbackTimer), fallbackStatus);
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, saving: false }));
    }
  };

  const startTimer = async () => {
    const date = form.getFieldValue('date');

    try {
      fallbackStatus = isRunning;
      fallbackTimer = convertDaysHrsMinsToMins(days, hours, minutes);
      start();

      setState((prevState) => ({ ...prevState, saving: true }));

      const prevLogsRes = await apiRequests.get(
        `${apiRoutes.TIME_LOG}?filters[user.uuid][]=${user.uuid}&filters[status][]=running`,
      );

      const requests = prevLogsRes.data.data.map((prevLog: any) => {
        return apiRequests.put(`${apiRoutes.TIME_LOG}/${prevLog.uuid}`, {
          started_at: '',
          status: 'paused',
          date: moment(prevLog.date).format('YYYY-MM-DD'),
          minutes: getDateDifferenceInMinutes(prevLog.started_at) + parseInt(prevLog.minutes, 10),
          ref_token: localRandomUuid,
        });
      });

      await Promise.all(requests);

      if (current) {
        const res = await apiRequests.put(`${apiRoutes.TIME_LOG}/${current.uuid}`, {
          started_at: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          minutes: parseInt(current.minutes, 10),
          date: moment(date).format('YYYY-MM-DD'),
          status: 'running',
          ref_token: localRandomUuid,
        });
        setState((prevState) => ({ ...prevState, saving: false, current: res.data.data }));
        dispatch(setActiveLogsActiveData(res.data.data));
      } else {
        form.setFieldsValue({ time: '00:00' });
        const res = await apiRequests.post(`${apiRoutes.TIME_LOG}`, {
          task_id,
          user_id: user.uuid,
          started_at: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          description: '',
          date: moment(date).format('YYYY-MM-DD'),
          status: 'running',
          minutes: '0',
          ref_token: localRandomUuid,
        });
        setState((prevState) => ({ ...prevState, saving: false, current: res.data.data }));
        dispatch(setActiveLogsActiveData(res.data.data));
      }

      dispatch(getActiveLogs());
    } catch (error) {
      reset(getOffsetTimestamp(fallbackTimer), fallbackStatus);
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, saving: false }));
    }
  };

  const pauseTimer = async () => {
    const date = form.getFieldValue('date');
    try {
      fallbackStatus = isRunning;
      fallbackTimer = convertDaysHrsMinsToMins(days, hours, minutes);
      pause();

      setState((prevState) => ({ ...prevState, saving: true }));
      const res = await apiRequests.put(`${apiRoutes.TIME_LOG}/${current?.uuid}`, {
        started_at: '',
        date: moment(date).format('YYYY-MM-DD'),
        minutes: getDateDifferenceInMinutes(current?.started_at) + parseInt(current?.minutes, 10),
        status: 'paused',
        ref_token: localRandomUuid,
      });
      setState((prevState) => ({ ...prevState, saving: false, current: res.data.data }));
      dispatch(setActiveLogsActiveData(res.data.data));
      dispatch(getActiveLogs());
    } catch (error) {
      reset(getOffsetTimestamp(fallbackTimer), fallbackStatus);

      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, saving: false }));
    }
  };

  const resetTimer = async () => {
    try {
      fallbackStatus = isRunning;
      fallbackTimer = convertDaysHrsMinsToMins(days, hours, minutes);
      reset(undefined, false);

      setState((prevState) => ({ ...prevState, saving: true }));
      await apiRequests.delete(`${apiRoutes.TIME_LOG}/${current?.uuid}`);
      if (current.uuid === activeLogs.activeData?.uuid) {
        dispatch(setActiveLogsActiveData(null));
      }
      setState((prevState) => ({ ...prevState, saving: false, current: null }));
      form.resetFields();
      onClose();
      dispatch(getActiveLogs());
    } catch (error) {
      reset(getOffsetTimestamp(fallbackTimer), fallbackStatus);

      asyncErrorHandler(error);
    }
  };

  const addEntry = async () => {
    try {
      fallbackStatus = isRunning;
      fallbackTimer = convertDaysHrsMinsToMins(days, hours, minutes);
      reset(undefined, false);

      const formData = await form.validateFields();
      const time = convertHrsMinsToMins(form.getFieldValue('time'));

      setState((prevState) => ({ ...prevState, saving: true }));

      if (current) {
        await apiRequests.put(`${apiRoutes.TIME_LOG}/${current.uuid}`, {
          started_at: '',
          description: formData.description,
          date: moment(formData.date).format('YYYY-MM-DD'),
          minutes: fallbackTimer > time ? fallbackTimer : time,
          status: 'completed',
          ref_token: localRandomUuid,
        });
        dispatch(setActiveLogsActiveData(null));
      } else {
        await apiRequests.post(`${apiRoutes.TIME_LOG}`, {
          started_at: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          description: formData.description,
          date: moment(formData.date).format('YYYY-MM-DD'),
          minutes: fallbackTimer > time ? fallbackTimer : time,
          status: 'completed',
          task_id,
          user_id: formData.user_id ?? user.uuid,
          ref_token: localRandomUuid,
        });
      }

      const totalRes = await apiRequests.get(
        `${apiRoutes.PROJECT_TASKS}/${current?.task?.uuid ?? task_id}/time-logs/${user.uuid}`,
      );

      form.setFieldsValue({
        description: '',
        time: '00:00',
        date: moment(new Date(Date.now())),
      });

      dispatch(getActiveLogs());

      onClose();
      setState((prevState) => ({
        ...prevState,
        saving: false,
        current: null,
        totalTime: parseInt(totalRes.data.data.sum_user_completed, 10),
        taskTotalTime: parseInt(totalRes.data.data.sum_completed, 10),
      }));

      if (onAddEntry) {
        onAddEntry();
      }
    } catch (error) {
      reset(getOffsetTimestamp(fallbackTimer), fallbackStatus);

      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, saving: false }));
    }
  };

  const fetchTaskTimelog = async (abort?: AbortController) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));

      const [runningResponse, totalResponse] = await Promise.all([
        apiRequests.get(
          apiRoutes.TIME_LOG,
          {
            'filters[task.uuid][]': task_id,
            'filters[user.uuid][]': user.uuid,
            'filters[status][]': ['running', 'paused'],
          },
          {},
          abort,
        ),
        apiRequests.get(`${apiRoutes.PROJECT_TASKS}/${task_id}/time-logs/${user.uuid}`, {}, {}, abort),
      ]);

      getDateDifferenceInMinutes(runningResponse.data.data.started_at);

      const currentData = runningResponse.data.data[0];

      setState((prevState) => ({
        ...prevState,
        current: currentData,
        user: currentData?.user,
        loading: false,
        totalTime: parseInt(totalResponse.data.data.sum_user_completed, 10),
        taskTotalTime: parseInt(totalResponse.data.data.sum_completed, 10),
      }));

      if (currentData?.status === 'running') {
        dispatch(setActiveLogsActiveData(currentData));
      }
    } catch (error) {
      if (error.code === 'ERR_CANCELED') return;
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false, error: true }));
    }
  };

  const onChangeTimer = (value: string) => {
    if (!current) return;

    const offsetTime = new Date();

    offsetTime.setMinutes(offsetTime.getMinutes() + convertHrsMinsToMins(value));

    reset(offsetTime, current.status === 'running');
  };

  useEffect(() => {
    const abort = new AbortController();
    const buttonClose = document.querySelector('.ant-modal-close');

    const handleClose = () => {
      onClose();
    };

    if (task_id && buttonClose) {
      buttonClose.addEventListener('click', handleClose);
    }

    if (task_id) {
      fetchTaskTimelog(abort);
    }

    return () => {
      abort.abort();

      if (task_id && buttonClose) {
        buttonClose.removeEventListener('click', handleClose);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task_id]);

  useEffect(() => {
    if (current) {
      form.setFieldsValue({ time: convertMinsToHrsMins(convertDaysHrsMinsToMins(days, hours, minutes)) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, hours, days]);

  // for the global nav timer
  useEffect(() => {
    if (globalActiveTimer) {
      setState((prevState) => ({ ...prevState, current: activeLogs.activeData }));
    }
  }, [activeLogs.activeData, globalActiveTimer]);

  // For the navbar dropdown timer
  useLayoutEffect(() => {
    if (timerContent) {
      setState((prevState) => ({ ...prevState, current: timerContent }));
    }
  }, [timerContent]);

  useEffect(() => {
    if (current) {
      const offsetTime = new Date();

      offsetTime.setMinutes(
        offsetTime.getMinutes() +
          parseInt(current.minutes, 10) +
          (!current.started_at ? 0 : getDateDifferenceInMinutes(current.started_at)),
      );

      reset(offsetTime, current.status === 'running');

      form.setFieldsValue({ ...current, date: moment(current.date), user_id: current.user.uuid });
    }
  }, [current, form, reset]);

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.timelog',
      callback: (event: any) => {
        if (event.payload.ref_token === localRandomUuid) return;

        if (task_id && event.payload.task_id === task_id) {
          if (event.action !== 'delete') {
            fetchTaskTimelog();
          } else {
            if (event.id === current?.uuid) {
              setState((prevState) => ({ ...prevState, current: null }));
              reset(undefined, false);
              form.setFieldsValue({ date: moment(), time: '00:00', description: '' });
            }

            if (event.id === activeLogs.activeData?.uuid) {
              setActiveLogsActiveData(null);
            }
          }
        }

        if (globalActiveTimer && event.payload.user_id === user.uuid) {
          dispatch(getActiveLogs());
        }
      },
    },
  });

  return {
    minutes,
    hours,
    days,
    isRunning,
    state,
    form,
    activeLogs,
    start,
    pause,
    startTimer,
    pauseTimer,
    addEntry,
    updateTimer,
    resetTimer,
    onChangeTimer,
  };
};

export default useTimer;
