import { FC, useEffect, useState } from 'react';
import { Button, Modal, Popconfirm, Space } from 'antd';
import UploadDragger from './UploadDragger';
import { UploadFileApi } from '../../types/upload-type';

interface FileUploadModalProps {
  visible: boolean;
  closeUploadModal: () => void;
  onOk?: (uploads: UploadFileApi[], onSuccess?: any) => void;
  okLoading?: boolean;
}

const FileUploadModal: FC<FileUploadModalProps> = ({ visible, closeUploadModal, onOk, okLoading }) => {
  const [uploads, setUploads] = useState<UploadFileApi[]>([]);
  const [loading, setLoading] = useState(false);

  const isDisabled = loading || okLoading;

  useEffect(() => {
    setUploads([]);
  }, [visible]);

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      bodyStyle={{
        padding: 0,
        margin: 0,
        paddingBottom: 16,
        marginBottom: 16,
        minHeight: '30vh',
        borderBottom: 'none',
      }}
      closable={false}
      className="modal-upload"
      visible={visible}
      width={600}
      title="Upload files"
      onCancel={isDisabled ? undefined : closeUploadModal}
      footer={
        <Space>
          <Popconfirm onConfirm={closeUploadModal} title="Do you want to cancel the upload?">
            <Button>Cancel</Button>
          </Popconfirm>
          <Button
            type="primary"
            disabled={okLoading || loading}
            onClick={() => {
              if (onOk) {
                onOk(uploads);
              }
            }}
          >
            Ok
          </Button>
        </Space>
      }
    >
      <div className="mb-l" />
      <UploadDragger setLoading={setLoading} uploads={uploads} setUploads={setUploads} showDownloadIcon={false} showRemoveIcon />
    </Modal>
  );
};

export default FileUploadModal;
