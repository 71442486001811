import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import apiRoutes from 'config/apiRoute';

const current = moment();

const useWeeklyTimer = () => {
  const user = useSelector((store: any) => store.auth.user);

  const [state, setState] = useState({
    loading: false,
    totalTimer: null as string | null,
    isBelow: false,
    todayTimer: null as any,
  });
  const globalLog = useSelector((globalState: any) => globalState.globalLog);
  const activeLogs = useSelector((globalState: any) => globalState.activeLogs);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prevState: any) => ({ ...prevState, loading: true }));
        const startOfWeek = current.startOf('week');
        const endOfWeek = current.endOf('week');

        const params: any = {
          'page[size]': undefined,
          'page[number]': undefined,
          'filters[status][]': 'completed',
        };

        params['filters[user_uuid][]'] = user.uuid;
        params.date_from = startOfWeek.format('YYYY-MM-DD');
        params.date_to = endOfWeek.format('YYYY-MM-DD');
        const res = await apiRequests.get(apiRoutes.TIME_LOG_REPORT, params);
        const totalTimer = res?.data?.total;
        const totalTimerHours =
          parseInt(totalTimer?.split('h')[0], 10) * 60 + parseInt(totalTimer?.split('h')[1]?.replace('m', ''), 10);
        const hoursNeedPerDay = 7.5;
        const requiredMins: any = {
          Sunday: 0,
          Monday: 0,
          Tuesday: hoursNeedPerDay * 60,
          Wednesday: hoursNeedPerDay * 2 * 60,
          Thursday: hoursNeedPerDay * 3 * 60,
          Friday: hoursNeedPerDay * 4 * 60,
          Saturday: hoursNeedPerDay * 5 * 60,
        };
        const currentRequiredCount = requiredMins[current.format('dddd')];

        params.date_to = moment().format('YYYY-MM-DD');
        params.date_from = moment().format('YYYY-MM-DD');
        const todayRes = await apiRequests.get(apiRoutes.TIME_LOG_REPORT, params);
        const todayTimer = todayRes?.data?.total;

        setState((prevState: any) => ({
          ...prevState,
          totalTimer,
          loading: false,
          isBelow: totalTimerHours < currentRequiredCount,
          todayTimer,
        }));
      } catch (error) {
        asyncErrorHandler(error);
        setState((prevState: any) => ({ ...prevState, loading: false }));
      }
    };

    fetchData();
  }, [user.uuid, globalLog.trigger, activeLogs.data]);

  return { state };
};

export default useWeeklyTimer;
