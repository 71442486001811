import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { firebaseConfig } from './firebaseConfig';

let messaging: null | ReturnType<typeof getMessaging> = null;

async function registerServiceWorker() {
  const registration = await navigator.serviceWorker.register('/firebase-sw.js', { scope: '/firebase-sw-push-scope' });

  const serviceWorker = registration.installing || registration.waiting || registration.active;

  if (!serviceWorker) return registration;

  await new Promise<void>((resolve) => {
    serviceWorker.addEventListener('statechange', (event) => {
      const target = event.target as ServiceWorker;

      if (target.state === 'activated') {
        resolve();
      }
    });

    if (serviceWorker.state === 'activated' || serviceWorker.state === 'installed') {
      resolve();
    }
  });

  return registration;
}

export async function requestToken() {
  if (!messaging) {
    messaging = getMessaging(initializeApp(firebaseConfig));
  }

  try {
    return await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      serviceWorkerRegistration: await registerServiceWorker(),
    });
  } catch (error) {
    console.error(error);
    return null;
  }
}
