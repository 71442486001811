import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getTaskCategory, setTaskCategoryData, setTaskCategoryError, setTaskCategoryLoading } from './taskCategory-slice';

const { get } = api;

function* getTaskCategorySagaListener(action: any) {
  try {
    yield put(setTaskCategoryLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.TASK_CATEGORIES, action.payload);
    yield put(setTaskCategoryData(res.data));
  } catch (error) {
    yield put(setTaskCategoryError(error));
    asyncErrorHandler(error);
  }
}

function* taskCategorySaga(): Generator<StrictEffect> {
  yield takeEvery(getTaskCategory.type, getTaskCategorySagaListener);
}

export default taskCategorySaga;
