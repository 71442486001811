import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { logoutUser } from 'services/auth';
import apiRoutes from '../config/apiRoute';

const headers: any = {};
headers['Content-Type'] = 'application/json';
headers.withCredentials = true;
headers.Accept = 'application/json';
headers['Access-Control-Allow-Origin'] = '*';

axios.defaults.withCredentials = true;

const setHeaderVersion = (version: string | null) => {
  if (version) {
    headers['api-version'] = version;
  } else {
    delete headers['api-version'];
  }
};

const handleAuthError = (error: AxiosError) => {
  if (error?.response?.status === 401) {
    message.error('Your not authorized.');
    logoutUser();
  } else if (error?.response?.status === 503) {
    message.error('The server is under maintenance, please wait..');
  }
  return null;
};

const getApi = (url: string, params = {}, header = {}, abort?: AbortController, ignoreError = false) => {
  return axios
    .get(url, {
      params,
      headers: { ...headers, ...header },
      signal: abort?.signal,
    })
    .then((response) => response)
    .catch((e) => {
      if (!ignoreError) {
        handleAuthError(e);
      }

      throw e;
    });
};

const postApi = (url: string, data = {}, header = {}, abort?: AbortController, ignoreError = false) => {
  return axios
    .post(url, data, {
      headers: { ...headers, ...header },
      signal: abort?.signal,
    })
    .then((response) => response)
    .catch((e) => {
      if (!ignoreError) {
        handleAuthError(e);
      }

      throw e;
    });
};

const deleteApi = (url: string, data = {}, params = {}, header = {}) => {
  return axios
    .delete(url, {
      data,
      params,
      headers: { ...headers, ...header },
    })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const patchApi = (url: string, data = {}, header = {}) => {
  return axios
    .patch(url, data, {
      headers: { ...headers, ...header },
    })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const putApi = (url: string, data = {}, header = {}, abort?: AbortController) => {
  return axios
    .put(url, data, {
      headers: { ...headers, ...header },
      signal: abort?.signal,
    })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const createPreSigned = async ({
  file,
  type = 'attachment',
  abortController,
}: {
  file: File;
  type?: 'attachment' | 'in-message';
  abortController?: AbortController;
}): Promise<{ url: string; uuid: string }> => {
  const fmData = {
    extension: file.name?.split('.').reverse()[0],
    size: file.size,
    name: file.name,
    type,
  };
  const res = await postApi(`${apiRoutes.CREATE_PRE_SIGNED}`, fmData, {}, abortController);
  return res.data;
};

const uploadFile = async ({
  file,
  type = 'attachment',
  abortController,
  onProgress,
}: {
  file: File;
  type?: 'attachment' | 'in-message';
  abortController?: AbortController;
  onProgress?: UploadRequestOption['onProgress'];
}) => {
  const preSignedRes = await createPreSigned({ file, type, abortController });

  await axios({
    method: 'PUT',
    data: file,
    url: preSignedRes.url,
    withCredentials: false,
    headers: {
      'Content-Type': file.type,
    },
    signal: abortController?.signal,
    onUploadProgress(progress) {
      if (onProgress && progress.progress) {
        onProgress({
          ...progress.event,
          percent: progress.progress * 100,
        });
      }
    },
  });

  const url = new URL(preSignedRes.url);

  preSignedRes.url = url.origin + url.pathname;

  return { ...preSignedRes, stated: false };
};

const apiRequests = {
  get: getApi,
  post: postApi,
  delete: deleteApi,
  patch: patchApi,
  put: putApi,
  uploadFile,
};

export { setHeaderVersion };

export default apiRequests;
