import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { addUnreadCounter, fetchUnreadCounter } from 'modules/inbox/store/inbox-slice';
import useWebSocket from 'utils/useWebSocket';

const useCountUnreadInbox = () => {
  const user = useSelector((store: RootState) => store.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUnreadCounter());
  }, [dispatch]);

  useWebSocket({
    channelName: `users.${user?.uuid}`,
    listen: {
      event: '.mention.change',
      callback: (event: any) => {
        if (event.action === 'create') {
          dispatch(addUnreadCounter(1));
          return;
        }

        dispatch(fetchUnreadCounter());
      },
    },
  });
};

export default useCountUnreadInbox;
