import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import {
  getDashboardDueToday,
  setDashboardDueTodayData,
  setDashboardDueTodayError,
  setDashboardDueTodayLoading,
} from './dashboardDueToday-slice';

const { get } = api;

function* getDashboardDueTodaySagaListener(action: any) {
  try {
    yield put(setDashboardDueTodayLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PROJECT_TASKS, action.payload);
    if (res?.data?.data?.length === 0) {
      action.payload['filters[due_soon_only][]'] = true;
      action.payload['filters[task_end_date][]'] = undefined;
      const newRes: AxiosResponse = yield call(get, apiRoutes.PROJECT_TASKS, action.payload);
      newRes.data.dueSoon = true;
      yield put(setDashboardDueTodayData(newRes.data));
    } else {
      yield put(setDashboardDueTodayData(res.data));
    }
  } catch (error) {
    yield put(setDashboardDueTodayError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardDueTodaySaga(): Generator<StrictEffect> {
  yield takeEvery(getDashboardDueToday.type, getDashboardDueTodaySagaListener);
}

export default dashboardDueTodaySaga;
