import { createSlice } from '@reduxjs/toolkit';

export const dashboardDueTodaySlice = createSlice({
  name: 'dashboardDueToday',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getDashboardDueToday: () => {},
    setDashboardDueTodayLoading: (state) => ({ ...state, loading: true }),
    setDashboardDueTodayData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setDashboardDueTodayError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setDashboardDueTodayData, setDashboardDueTodayError, setDashboardDueTodayLoading, getDashboardDueToday } =
  dashboardDueTodaySlice.actions;

export default dashboardDueTodaySlice.reducer;
