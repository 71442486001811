import { ComponentType, lazy, LazyExoticComponent } from 'react';
import Reloading from '../components/Reloading';

const lazyRetry = <T extends ComponentType<any>>(
  importComponent: () => Promise<{ default: T }>,
): LazyExoticComponent<T | typeof Reloading> =>
  lazy<T | typeof Reloading>(async () => {
    const stringifierFunction = importComponent.toString();
    const componentsRefreshed: string[] = JSON.parse(sessionStorage.getItem('reloaded-components') ?? '[]');
    let refreshedComponents: Set<string>;

    if (Array.isArray(componentsRefreshed)) {
      refreshedComponents = new Set(componentsRefreshed);
    } else {
      throw Error('Unexpected value from data store');
    }

    const hasComponentRefreshed = refreshedComponents.has(stringifierFunction);

    try {
      const component = await importComponent();
      refreshedComponents.delete(stringifierFunction);
      sessionStorage.setItem('reloaded-components', JSON.stringify(Array.from(refreshedComponents)));
      return component;
    } catch (error) {
      if (!hasComponentRefreshed) {
        refreshedComponents.add(stringifierFunction);
        sessionStorage.setItem('reloaded-components', JSON.stringify(Array.from(refreshedComponents)));
        window.location.reload();
        return { default: Reloading };
      }

      throw error;
    }
  });

export { lazyRetry };
