import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import reducers from './combine-reducers';
import rootSagas from './combine-sagas';

const sagaMiddleware = createSagaMiddleware();

const combinedReducer = combineReducers(reducers);

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'store/reset') {
    state = {
      auth: state.auth,
    };
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      sagaMiddleware,
    ];
  },
});

sagaMiddleware.run(rootSagas);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export default store;
