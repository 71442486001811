import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getBluePrints, setBluePrintsData, setBluePrintsError, setBluePrintsLoading } from './bluePrints-slice';

const { get } = api;

function* getBluePrintsSagaListener(action: any) {
  try {
    yield put(setBluePrintsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.FORMS_BLUE_PRINTS, action.payload);
    yield put(setBluePrintsData(res.data));
  } catch (error) {
    yield put(setBluePrintsError(error));
    asyncErrorHandler(error);
  }
}
function* bluePrintsSaga(): Generator<StrictEffect> {
  yield takeLatest(getBluePrints.type, getBluePrintsSagaListener);
}

export default bluePrintsSaga;
