import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import {
  getDashboardPastDue,
  setDashboardPastDueData,
  setDashboardPastDueError,
  setDashboardPastDueLoading,
} from './dashboardPastDue-slice';

const { get } = api;

function* getDashboardPastDueSagaListener(action: any) {
  try {
    yield put(setDashboardPastDueLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PROJECT_TASKS, action.payload);
    yield put(setDashboardPastDueData(res.data));
  } catch (error) {
    yield put(setDashboardPastDueError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardPastDueSaga(): Generator<StrictEffect> {
  yield takeEvery(getDashboardPastDue.type, getDashboardPastDueSagaListener);
}

export default dashboardPastDueSaga;
