import { FC, MouseEvent } from 'react';
import styles from './index.module.less';

interface DropdownHeaderProps {
  onMarkAllRead: () => void;
}

const DropdownHeader: FC<DropdownHeaderProps> = ({ onMarkAllRead }) => {
  const markAllReadHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onMarkAllRead();
  };

  return (
    <div className={styles.dropdownHeader}>
      <span>NOTIFICATIONS</span>

      <button type="button" className="default-button" onClick={markAllReadHandler}>
        Mark All Read
      </button>
    </div>
  );
};

export default DropdownHeader;
