import { createAppTableSaga, createAppTableStore, createPersist } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';

export const projectAnalyticsSlice = createAppTableStore({
  name: 'projectsAnalytics' as const,
  url: apiRoutes.PROJECTS_ADVERTISING,
  initialState: {
    params: {
      filters: {
        'projects.status': [{ value: 'paused' }, { value: 'waiting_to_launch' }, { value: 'in_progress' }, { value: 'active' }],
        advertising: { value: true },
      },
      sort: { column: 'title', order: 'ascend' },
    },
    paramFilterFields: {
      advertising: 'filters[advertising][]',
    },
    ignoreFetch: true,
  },
});

export const projectsAnalyticsSaga = createAppTableSaga(projectAnalyticsSlice);

export const projectsAnalyticsReducer = createPersist(projectAnalyticsSlice);
