import { FC, useState } from 'react';
import { message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import styles from './style.module.less';

export interface ButtonDownloadAllFilesProps {
  resource: 'task' | 'request';
  ids: string[];
}

const ButtonDownloadAllFiles: FC<ButtonDownloadAllFilesProps> = ({ resource, ids }) => {
  const [downloading, setDownloading] = useState(false);

  const clickHandler = async () => {
    try {
      setDownloading(true);

      const res = await apiRequests.post(`${apiRoutes.MASS_DOWNLOAD}`, {
        full_download: true,
        [`${resource}_ids`]: ids,
      });

      message.success(res?.data?.message);
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <button type="button" onClick={clickHandler} className={styles.btnDownloadAll} disabled={downloading}>
      <DownloadOutlined />
      Download all files
    </button>
  );
};

export default ButtonDownloadAllFiles;
