import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getGroups, setGroupsData, setGroupsError, setGroupsLoading } from './groups-slice';

const { get } = api;

function* getGroupSagaListener(action: any) {
  try {
    yield put(setGroupsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.GROUPS, action.payload);
    yield put(setGroupsData(res.data));
  } catch (error) {
    yield put(setGroupsError(error));
    asyncErrorHandler(error);
  }
}

function* groupSaga(): Generator<StrictEffect> {
  yield takeEvery(getGroups.type, getGroupSagaListener);
}

export default groupSaga;
