import { createSlice } from '@reduxjs/toolkit';

export const dashboardProjectLogsSlice = createSlice({
  name: 'dashboardProjectLogs',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getDashboardProjectLogs: () => {},
    setDashboardProjectLogsLoading: (state) => ({ ...state, loading: true }),
    setDashboardProjectLogsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setDashboardProjectLogsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const {
  setDashboardProjectLogsData,
  setDashboardProjectLogsError,
  setDashboardProjectLogsLoading,
  getDashboardProjectLogs,
} = dashboardProjectLogsSlice.actions;

export default dashboardProjectLogsSlice.reducer;
