/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const administrationSlice = createSlice({
  name: 'administration',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getAdministration: (payload?: any) => {},
    setAdministrationLoading: (state) => ({ ...state, loading: true }),
    setAdministrationData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setAdministrationError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setAdministrationData, setAdministrationError, setAdministrationLoading, getAdministration } =
  administrationSlice.actions;

export default administrationSlice.reducer;
