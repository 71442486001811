import React, { FC, useState } from 'react';
import { Form, message, Modal, Typography } from 'antd';
import Comments from 'components/Comments';
import ProjectSelect from 'modules/project/components/ProjectSelect';

interface LogModalProps {
  visible: boolean;
  onClose: () => void;
}

const { Text } = Typography;

const LogModal: FC<LogModalProps> = ({ visible, onClose }) => {
  const [project, setProject] = useState<any>(null);

  return (
    <Modal
      width={700}
      destroyOnClose
      afterClose={() => {
        setProject(null);
      }}
      footer={null}
      title={<Text className="primary">Add Project Log</Text>}
      visible={visible}
      onCancel={onClose}
    >
      <Form layout="vertical">
        <ProjectSelect
          label
          allowClear
          name="project"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
          onChange={(value, option: any) => {
            setProject(option?.resource);
          }}
        />
      </Form>
      {project?.uuid && (
        <Comments
          type="projects"
          typeUuid={project?.uuid}
          projectUuid={project?.uuid}
          maxHeight="370px"
          hideTabs
          hideComments
          onAddComment={() => {
            message.success('Log added.');
            onClose();
          }}
        />
      )}
    </Modal>
  );
};

export default LogModal;
