import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import {
  getAdministration,
  setAdministrationData,
  setAdministrationError,
  setAdministrationLoading,
} from './administration-slice';

const { get } = api;

function* getAdministrationSagaListener(action: any) {
  try {
    yield put(setAdministrationLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.ADMINISTRATION, action.payload);
    yield put(setAdministrationData(res.data));
  } catch (error) {
    yield put(setAdministrationError(error));
    asyncErrorHandler(error);
  }
}

function* administrationSaga(): Generator<StrictEffect> {
  yield takeEvery(getAdministration.type, getAdministrationSagaListener);
}

export default administrationSaga;
