/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const productTypesSlice = createSlice({
  name: 'productTypes',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getProductTypes: (payload?: any) => {},
    setProductTypesLoading: (state) => ({ ...state, loading: true }),
    setProductTypesData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setProductTypesError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setProductTypesData, setProductTypesError, setProductTypesLoading, getProductTypes } = productTypesSlice.actions;

export default productTypesSlice.reducer;
