import { FC, useRef } from 'react';
import { message, Upload, UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import ButtonDownloadAllFiles, { ButtonDownloadAllFilesProps } from 'components/ButtonDownloadAllFiles';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { UploadFileApi } from 'types/upload-type';

import IconUploadFile from '../IconUploadFile';
import useWebSocket from '../../utils/useWebSocket';

const { Dragger } = Upload;

interface UploadDraggerProps {
  setUploads: (file: UploadFileApi[] | ((file: UploadFileApi[]) => UploadFileApi[])) => void;
  uploads: UploadFileApi[];
  setLoading: (loading: boolean) => void;
  showRemoveIcon: boolean;
  showDownloadIcon: boolean;
  resource?: ButtonDownloadAllFilesProps['resource'];
  resourceIds?: ButtonDownloadAllFilesProps['ids'];
  onRequest?: (response: { url: string; uuid: string }) => Promise<unknown>;
  onRemove?: (file: UploadFileApi) => void;
}

const UploadDragger: FC<UploadDraggerProps> = ({
  uploads,
  setUploads,
  setLoading,
  showDownloadIcon,
  showRemoveIcon,
  resource,
  resourceIds,
  onRequest,
  onRemove: onRemoveParent,
}) => {
  const filesDownloadingRef = useRef<string[]>([]);

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const { onSuccess, onError, onProgress, file } = options;

    const abort = new AbortController();

    const current = uploads.find((item) => item.uid === (file as RcFile).uid);

    if (current) current.abortController = abort;

    try {
      const response = await apiRequests.uploadFile({
        file: file as File,
        type: 'attachment',
        abortController: abort,
        onProgress,
      });

      if (onRequest) {
        const sentToApi: any = await onRequest(response);

        if (sentToApi) {
          response.url = response.url.replace('/tmp/', '/uploads/');
          response.stated = true;
        }
      }

      if (onSuccess) onSuccess(response);
    } catch (err) {
      if (err.code === 'ERR_CANCELED') return;

      if (onError)
        onError({
          status: 500,
          method: 'PUT',
          url: 'tes',
          name: 'eeee',
          message: 'tess',
        });
    }
  };

  const onChange = ({ file, fileList }: UploadChangeParam<UploadFileApi>) => {
    const hasLoading = fileList.find((item) => item.status === 'uploading');

    setLoading(!!hasLoading);

    if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }

    if (file.status === 'done' && file.response?.url) {
      file.url = file.response.url;
    }

    setUploads(fileList);
  };

  const onRemove: UploadProps['onRemove'] = (upload) => {
    if (onRemoveParent) onRemoveParent(upload);

    if (upload.response?.stated) {
      apiRequests.delete(`${apiRoutes.UPLOADS}/${upload.response.uuid}`).catch((error) => {
        asyncErrorHandler(error);
      });
    }
  };

  const onDownload: UploadProps['onDownload'] = (upload: UploadFileApi) => {
    const uuid = upload.response?.uuid;

    if (!uuid || filesDownloadingRef.current.includes(upload.uid)) {
      return;
    }

    filesDownloadingRef.current.push(uuid);

    apiRequests
      .get(`${apiRoutes.UPLOADS}/${uuid}/download`)
      .then((response) => {
        const link = document.createElement('a');
        link.href = response.data;
        link.download = upload.name;
        link.click();
      })
      .catch((error) => {
        asyncErrorHandler(error);
      })
      .finally(() => {
        const index = filesDownloadingRef.current.indexOf(uuid);

        if (index > -1) {
          filesDownloadingRef.current.splice(index, 1);
        }
      });
  };

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.foundation.upload',
      callback: (event: any) => {
        if (event.action !== 'delete') return;

        const newUploads = uploads.filter((upload) => upload.response?.uuid !== event.id);

        if (newUploads.length !== uploads.length) {
          setUploads(newUploads);
        }
      },
    },
  });

  return (
    <>
      <Dragger
        customRequest={customRequest}
        onChange={onChange}
        onRemove={onRemove}
        onDownload={onDownload}
        listType="picture"
        fileList={uploads}
        showUploadList={{
          showPreviewIcon: true,
          showDownloadIcon,
          showRemoveIcon,
        }}
        multiple
        iconRender={(upload: UploadFileApi) => <IconUploadFile file={upload} />}
      >
        <div>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for a single or bulk upload.</p>
        </div>
      </Dragger>

      {resource && resourceIds && uploads.length > 0 && <ButtonDownloadAllFiles resource={resource} ids={resourceIds} />}
    </>
  );
};

export default UploadDragger;
