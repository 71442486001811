import { FC, ReactNode } from 'react';
import ENVIRONMENT from 'config/environment';
import Maintenance from './index';

interface CheckMaintenanceProps {
  children: ReactNode;
  allowInMaintenance?: boolean;
  user: any;
}

const whiteList = ['lucas@savageglobalent.com', 'marcelo@savageglobalent.com', 'santiago@savageglobalent.com'];

const CheckMaintenance: FC<CheckMaintenanceProps> = ({ user, allowInMaintenance = false, children }) => {
  const isInMaintenance = ENVIRONMENT.REACT_APP_MAINTENANCE;

  if (!allowInMaintenance && isInMaintenance && (!user || !whiteList.includes(user.email))) {
    return <Maintenance />;
  }

  return <>{children}</>;
};

export default CheckMaintenance;
