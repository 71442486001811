import { FC, useRef, useState } from 'react';
import { Button, Checkbox, Popover, Radio, Space, Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'store';
import { updateAuthData } from 'store/container/auth/auth-slice';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import SmsSignUpModal from '../SmsSignUpModal';
import { UserResponse } from '../../types';

export type NotificationStatus = 'all' | 'read' | 'unread';

type TimoutAlert = {
  email?: ReturnType<typeof setTimeout>;
  sms?: ReturnType<typeof setTimeout>;
  push?: ReturnType<typeof setTimeout>;
};

interface DropdownFooterProps {
  className?: string;
  notificationStatus: NotificationStatus;
  onNotificationStatusChange: (item: NotificationStatus) => void;
}

const DropdownFooter: FC<DropdownFooterProps> = ({ className, notificationStatus, onNotificationStatusChange }) => {
  const user = useSelector((store: RootState) => store.auth.user) as UserResponse;
  const [smsModalOpen, setSmsModalOpen] = useState(false);
  const [smsAlertOpen, setSmsAlertOpen] = useState(false);
  const [emailAlertOpen, setEmailAlertOpen] = useState(false);
  const [pushAlertOpen, setPushAlertOpen] = useState(false);
  const timeoutAlertRef = useRef<TimoutAlert>({});

  const dispatch = useDispatch();

  const updateUser = async (data: any) => {
    try {
      await apiRequests.put(`${apiRoutes.CONTACTS}/${user.contact?.uuid}`, {
        preferences: data,
      });
    } catch (error) {
      asyncErrorHandler(error);
    }
  };

  const onPermissionChange = (event: CheckboxChangeEvent) => {
    const data = { [event.target.name ?? '']: event.target.checked ? '1' : '0' };

    const newPreferences = {
      ...user.preferences,
      ...data,
    };

    if (newPreferences.email_notifications === '0' && newPreferences.sms_notifications === '0') {
      if (event.target.name === 'sms_notifications') {
        clearTimeout(timeoutAlertRef.current.sms);
        setSmsAlertOpen(true);
        timeoutAlertRef.current.sms = setTimeout(() => {
          setSmsAlertOpen(false);
        }, 2000);
      }

      if (event.target.name === 'email_notifications') {
        clearTimeout(timeoutAlertRef.current.email);
        setEmailAlertOpen(true);
        timeoutAlertRef.current.email = setTimeout(() => {
          setEmailAlertOpen(false);
        }, 2000);
      }

      return;
    }

    if (smsAlertOpen) {
      setSmsAlertOpen(false);
    }

    if (emailAlertOpen) {
      setEmailAlertOpen(false);
    }

    if (pushAlertOpen) {
      setPushAlertOpen(false);
    }

    if (newPreferences.push_notifications === '1' && event.target.name === 'push_notifications') {
      localStorage.removeItem('showNotificationPermission');

      if ('Notification' in window && Notification.permission === 'denied') {
        clearTimeout(timeoutAlertRef.current.push);
        setPushAlertOpen(true);
        timeoutAlertRef.current.push = setTimeout(() => {
          setPushAlertOpen(false);
        }, 2000);
      }
    }

    if (newPreferences.sms_notifications === '1' && event.target.name === 'sms_notifications') {
      setSmsModalOpen(true);
      return;
    }

    dispatch(
      updateAuthData({
        ...user,
        preferences: {
          ...newPreferences,
        },
      }),
    );

    updateUser(data);
  };

  const onPopoverChannelsOpen = (open: boolean) => {
    if (!open) {
      setEmailAlertOpen(false);
      setSmsAlertOpen(false);
      setPushAlertOpen(false);
      return;
    }

    if (user.preferences.push_notifications === '1' && 'Notification' in window && Notification.permission === 'denied') {
      clearTimeout(timeoutAlertRef.current.push);
      setPushAlertOpen(true);
      timeoutAlertRef.current.push = setTimeout(() => {
        setPushAlertOpen(false);
      }, 2000);
    }
  };

  return (
    <div className={className} onClick={(event) => event.stopPropagation()}>
      {smsModalOpen && user && <SmsSignUpModal user={user} onClose={() => setSmsModalOpen(false)} />}

      <Radio.Group
        options={[
          { label: 'All', value: 'all' },
          { label: 'Unread', value: 'unread' },
          { label: 'Read', value: 'read' },
        ]}
        onChange={({ target }) => onNotificationStatusChange(target.value)}
        value={notificationStatus}
        optionType="button"
        buttonStyle="solid"
        size="small"
      />

      <Popover
        title="Notifications channels"
        placement="leftTop"
        trigger="click"
        overlayStyle={{ zIndex: 1060 }}
        destroyTooltipOnHide
        onVisibleChange={onPopoverChannelsOpen}
        content={
          <Space direction="vertical">
            <Checkbox
              name="email_notifications"
              checked={user.preferences.email_notifications === '1'}
              onChange={onPermissionChange}
            >
              <Tooltip
                visible={emailAlertOpen}
                placement="right"
                title="You need to have at least SMS or email notification enabled"
                overlayStyle={{ maxWidth: 180 }}
              >
                Email
              </Tooltip>
            </Checkbox>

            <Checkbox name="sms_notifications" checked={user.preferences.sms_notifications === '1'} onChange={onPermissionChange}>
              <Tooltip
                visible={smsAlertOpen}
                placement="right"
                title="You need to have at least SMS or email notification enabled"
                overlayStyle={{ maxWidth: 180 }}
              >
                SMS
              </Tooltip>
            </Checkbox>

            <Checkbox
              name="push_notifications"
              checked={user.preferences.push_notifications === '1'}
              onChange={onPermissionChange}
            >
              <Tooltip
                visible={pushAlertOpen}
                placement="right"
                title="Permission denied, please enable the notification permission on this browser"
                overlayStyle={{ maxWidth: 180 }}
              >
                Push
              </Tooltip>
            </Checkbox>
          </Space>
        }
      >
        <Button type="link" size="small" icon={<SettingOutlined style={{ fontSize: 24 }} />} />
      </Popover>
    </div>
  );
};

export default DropdownFooter;
