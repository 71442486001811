import { createAppTableSaga, createAppTableStore, createPersist } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';

export const clientsSlice = createAppTableStore({
  name: 'clients' as const,
  url: apiRoutes.CUSTOMERS,
  initialState: {
    params: {
      filters: { status: { value: 'active' } },
      sort: { column: 'phone', order: 'descend' },
    },
    paramFilterFields: {
      status: 'filters[status][]',
    },
  },
});

export const clientsSaga = createAppTableSaga(clientsSlice);

export const clientsReducer = createPersist(clientsSlice);
