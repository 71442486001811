import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const projectSelectSlice = createSlice({
  name: 'projectSelect',
  initialState,
  reducers: {
    getProjectSelect: () => {},
    setProjectSelectLoading: (state) => ({ ...state, loading: true }),
    setProjectSelectData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setProjectSelectError: (state, action) => ({ ...state, loading: false, error: action.payload }),
    clearProjectSelectData: () => initialState,
  },
});

export const { setProjectSelectData, setProjectSelectError, setProjectSelectLoading, getProjectSelect, clearProjectSelectData } =
  projectSelectSlice.actions;

export default projectSelectSlice.reducer;
