import { createSlice } from '@reduxjs/toolkit';

export const versionUpdateSlice = createSlice({
  name: 'versionUpdate',
  initialState: {
    newUpdate: false,
  },
  reducers: {
    setVersionUpdate: (state) => ({ ...state, newUpdate: true }),
  },
});

export const { setVersionUpdate } = versionUpdateSlice.actions;

export default versionUpdateSlice.reducer;
