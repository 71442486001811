import { createSlice } from '@reduxjs/toolkit';

export const GroupSlicer = createSlice({
  name: 'groups',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getGroups: () => {},
    setGroupsLoading: (state) => ({ ...state, loading: true }),
    setGroupsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setGroupsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setGroupsData, setGroupsError, setGroupsLoading, getGroups } = GroupSlicer.actions;

export default GroupSlicer.reducer;
