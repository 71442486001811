import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import {
  getDashboardRecentTasks,
  setDashboardRecentTasksData,
  setDashboardRecentTasksError,
  setDashboardRecentTasksLoading,
} from './dashboardRecentTasks-slice';

const { get } = api;

function* getDashboardRecentTasksSagaListener(action: any) {
  try {
    yield put(setDashboardRecentTasksLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.RECENT_TASKS, action.payload);
    yield put(setDashboardRecentTasksData(res.data));
  } catch (error) {
    yield put(setDashboardRecentTasksError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardPastSaga(): Generator<StrictEffect> {
  yield takeEvery(getDashboardRecentTasks.type, getDashboardRecentTasksSagaListener);
}

export default dashboardPastSaga;
