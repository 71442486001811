import { FC } from 'react';
import { FilePdfOutlined, FileOutlined, FileExcelOutlined, FileWordOutlined, FileZipOutlined } from '@ant-design/icons';
import { UploadFileApi } from 'types/upload-type';

interface IconUploadFileProps {
  file: UploadFileApi;
}

const IconUploadFile: FC<IconUploadFileProps> = ({ file }) => {
  if (file.extension === 'pdf' || file.type === 'application/pdf') {
    return <FilePdfOutlined style={{ color: '#b91c1c' }} />;
  }

  if (
    ['xlsx', 'xlsm', 'xls', 'xlt'].includes(file.extension || '') ||
    ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type || '')
  ) {
    return <FileExcelOutlined style={{ color: '#15803d' }} />;
  }

  if (
    ['doc', 'docx', 'dotx', 'dot'].includes(file.extension || '') ||
    ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type || '')
  ) {
    return <FileWordOutlined style={{ color: '#1d4ed8' }} />;
  }

  if (
    ['zip', '7z', 'rar'].includes(file.extension || '') ||
    ['application/zip', 'application/x-7z-compressed', 'application/vnd.rar'].includes(file.type || '')
  ) {
    return <FileZipOutlined />;
  }

  return <FileOutlined />;
};

export default IconUploadFile;
