import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const blueprintsSlice = createSlice({
  name: 'blueprints',
  initialState,
  reducers: {
    getBluePrints: () => {},
    setBluePrintsLoading: (state) => ({ ...state, loading: true }),
    setBluePrintsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    clearBluePrintsData: () => initialState,
    setBluePrintsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setBluePrintsData, setBluePrintsError, setBluePrintsLoading, getBluePrints, clearBluePrintsData } =
  blueprintsSlice.actions;

export default blueprintsSlice.reducer;
