/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const activeLogsSlice = createSlice({
  name: 'activeLogs',
  initialState: {
    loading: false,
    data: null,
    activeData: null,
    effect: 0,
  },
  reducers: {
    getActiveLogs: (state) => ({ ...state, effect: state?.effect + 1 }),
    setActiveLogsLoading: (state) => ({ ...state, loading: true, error: null }),
    setActiveLogsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setActiveLogsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
    setActiveLogsActiveData: (state, action) => ({ ...state, activeData: action.payload }),
  },
});

export const { setActiveLogsData, setActiveLogsActiveData, setActiveLogsError, setActiveLogsLoading, getActiveLogs } =
  activeLogsSlice.actions;

export default activeLogsSlice.reducer;
