import { createSlice } from '@reduxjs/toolkit';

export const dashboardRecentTasksSlice = createSlice({
  name: 'dashboardRecentTasks',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getDashboardRecentTasks: () => {},
    setDashboardRecentTasksLoading: (state) => ({ ...state, loading: true }),
    setDashboardRecentTasksData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setDashboardRecentTasksError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const {
  setDashboardRecentTasksData,
  setDashboardRecentTasksError,
  setDashboardRecentTasksLoading,
  getDashboardRecentTasks,
} = dashboardRecentTasksSlice.actions;

export default dashboardRecentTasksSlice.reducer;
