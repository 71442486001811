/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import type SimpleBarCore from 'simplebar-core';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { getQueryValue } from 'utils/string.utils';
import useWebSocket from 'utils/useWebSocket';
import { RichTextEditorHandle } from 'components/RichTextEditor';

const PAGE_SIZE = 100;

// @ts-ignore
const localRandomUuid: string = window.crypto.randomUUID();

interface CommentsParams {
  highlightUuid?: string;
  typeUuid: string;
  type: 'tasks' | 'projects' | 'requests';
  onAddComment: (() => void) | undefined;
  onCommentsLoaded: any;
  onFilesDelete?: (uuid: string[]) => void;
}

const typeEndpoints: Record<CommentsParams['type'], string> = {
  tasks: apiRoutes.PROJECT_TASKS,
  projects: apiRoutes.PROJECTS,
  requests: apiRoutes.REQUESTS,
};

const typeKeys: Record<CommentsParams['type'], string> = {
  tasks: 'task_id',
  projects: 'project_id',
  requests: 'request_id',
};

export const deletedMessage = '[Comment Deleted]';

const useComments = ({ highlightUuid, typeUuid, type, onAddComment, onCommentsLoaded, onFilesDelete }: CommentsParams) => {
  const [commentType, setCommentType] = useState('comment');
  const [state, setState] = useState({
    data: [] as Record<string, any>[],
    loading: false,
    current: 1,
    hasMore: true,
    adding: false,
    replyTo: null as any,
    replyToUuid: null as string | null,
    scrollToTarget: true,
  });
  const [tabView, setTabView] = useState('all');

  const scrollRef = useRef<SimpleBarCore>(null);
  const inputRef = useRef<RichTextEditorHandle>(null);
  const { current, data } = state;
  const endpoint = `${typeEndpoints[type]}/${typeUuid}/comments`;
  const history = useHistory();

  const { search } = history.location;
  const targetCommentId = highlightUuid ?? getQueryValue(search, 'target_comment_id');

  const setComment = (text: string) => {
    inputRef.current?.updateValue(text);
  };

  const setReplyTo = (replyToUuid: string | null, value: any, creator?: any) => {
    setState((prevState) => ({ ...prevState, replyTo: value, replyToUuid }));

    if (value?.type === 'private') {
      setCommentType(value.type);
    }

    if (creator) {
      inputRef.current?.setMentionContents({
        id: creator.uuid,
        name: creator.name,
      });
    } else {
      inputRef.current?.focus();
    }

    if (onCommentsLoaded) {
      onCommentsLoaded(false);
    }
  };

  const scrollToBottom = () => {
    const scrollElement = scrollRef.current?.getScrollElement();
    if (scrollElement) {
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  };

  const scrollTo = (offsetTop: any) => {
    const scrollElement = scrollRef.current?.getScrollElement();
    if (scrollElement) {
      scrollElement.scrollTop = offsetTop;
    }
  };

  const handleAddComments = async (values: any = {}, onFinish: any) => {
    try {
      setState((prevState) => ({ ...prevState, adding: true }));

      const res = await apiRequests.post(endpoint, {
        ...values,
        ref_token: localRandomUuid,
      });

      setState((prevState) => ({
        ...prevState,
        adding: false,
        data: [...prevState.data, res.data.data],
        current: 1,
        hasMore: prevState.data.length >= PAGE_SIZE,
      }));

      if (onAddComment) {
        onAddComment();
      }

      setTimeout(() => scrollToBottom(), 300);
      onFinish();
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, adding: false }));
    }
  };

  const handleAddReply = async (commentId: string, values: any, onFinish: any) => {
    try {
      setState((prevState) => ({ ...prevState, adding: true }));

      const res = await apiRequests.post(`${apiRoutes.BASE_URL}/projects/comments/${commentId}/comments`, {
        ...values,
        ref_token: localRandomUuid,
      });

      setState((prevState) => {
        const newData = prevState.data.map((el) =>
          el.uuid === commentId
            ? {
                ...el,
                replies: [...el.replies, res.data.data],
              }
            : el,
        );
        return {
          ...prevState,
          adding: false,
          data: newData,
        };
      });

      onFinish();

      setTimeout(() => {
        const scrollEl = scrollRef?.current?.getScrollElement();
        const targetCommentEl = scrollEl?.querySelector<HTMLElement>(`.comment-${commentId}`);
        const targetReplyEl = scrollEl?.querySelector<HTMLElement>(`.comment-${res.data.data.uuid}`);

        if (targetCommentEl && targetReplyEl) {
          const finVal = targetCommentEl.offsetTop + targetCommentEl.scrollHeight - targetReplyEl.scrollHeight;
          scrollTo(finVal);
        }
      }, 300);
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, adding: false }));
    }
  };

  const handleUpdateComment = async (_uuid: any, value: any, users: any, onFinish: any) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));

      const res = await apiRequests.put(`${apiRoutes.COMMENTS}/${_uuid}`, {
        text: value,
        related_users: users ?? undefined,
        ref_token: localRandomUuid,
      });

      setState((prevState) => ({
        ...prevState,
        loading: false,
        data: prevState.data.map((el) => (el.uuid === _uuid ? res.data.data : el)),
      }));
      onFinish();
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleUpdateReply = async (_uuid: any, commentId: any, value: any, users: any, onFinish: any) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));

      const res = await apiRequests.put(`${apiRoutes.COMMENTS}/${_uuid}`, {
        text: value,
        related_users: users ?? undefined,
        ref_token: localRandomUuid,
      });

      setState((prevState) => {
        const newData = prevState.data.map((el) =>
          el.uuid === commentId
            ? { ...el, replies: el.replies.map((reply: any) => (reply.uuid === _uuid ? res.data.data : reply)) }
            : el,
        );

        return {
          ...prevState,
          loading: false,
          data: newData,
        };
      });
      onFinish();
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleDeleteUpload = async (_uuid: string, commentId: string) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));

      await apiRequests.delete(`${apiRoutes.UPLOADS}/${_uuid}`);

      setState((prevState) => ({
        ...prevState,
        data: prevState.data.map((el) =>
          el.uuid === commentId ? { ...el, uploads: el.uploads.filter((upload: any) => upload.uuid !== _uuid) } : el,
        ),
        loading: false,
      }));

      onFilesDelete?.([_uuid]);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const getCommentDeleted = (comment: Record<string, any>) => {
    return { ...comment, text: deletedMessage, uploads: [], replies: [], deleted_at: moment().format() };
  };

  const handleDeleteComment = async (_uuid: any) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));

      await apiRequests.delete(`${apiRoutes.COMMENTS}/${_uuid}`);

      setState((prevState) => ({
        ...prevState,
        loading: false,
        data: prevState.data.map((el) => (el.uuid === _uuid ? getCommentDeleted(el) : el)),
      }));
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleDeleteReply = async (_uuid: any, commentId: any) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));

      await apiRequests.delete(`${apiRoutes.COMMENTS}/${_uuid}`);

      setState((prevState) => {
        const newData = prevState.data.map((el) =>
          el.uuid === commentId
            ? {
                ...el,
                replies: el.replies.map((reply: any) => (reply.uuid === _uuid ? getCommentDeleted(reply) : reply)),
              }
            : el,
        );

        return {
          ...prevState,
          loading: false,
          data: newData,
        };
      });
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleAddReaction = (commentId: string, reaction: any) => {
    setState((prevState) => ({
      ...prevState,
      data: prevState.data.map((el) => (el.uuid === commentId ? { ...el, reactions: [...el.reactions, reaction] } : el)),
    }));
  };

  const handleAddReplyReaction = (commentId: string, replyId: string, reaction: any) => {
    setState((prevState) => ({
      ...prevState,
      data: prevState.data.map((el) =>
        el.uuid === commentId
          ? {
              ...el,
              replies: el.replies.map((reply: any) =>
                reply.uuid === replyId ? { ...reply, reactions: [...reply.reactions, reaction] } : reply,
              ),
            }
          : el,
      ),
    }));
  };

  const handleRemoveReplyReaction = (commentId: string, replyId: string, reaction: any) => {
    setState((prevState) => ({
      ...prevState,
      data: prevState.data.map((el) =>
        el.uuid === commentId
          ? {
              ...el,
              replies: el.replies.map((reply: any) =>
                reply.uuid === replyId
                  ? { ...reply, reactions: reply.reactions.filter((item: any) => item.uuid !== reaction.uuid) }
                  : reply,
              ),
            }
          : el,
      ),
    }));
  };

  const handleRemoveReaction = (commentId: string, reaction: any) => {
    setState((prevState) => ({
      ...prevState,
      data: prevState.data.map((el) =>
        el.uuid === commentId ? { ...el, reactions: el.reactions.filter((item: any) => item.uuid !== reaction.uuid) } : el,
      ),
    }));
  };

  const fetchComments = async (fnCurrent: number, abort?: AbortController) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      current: fnCurrent,
    }));

    const params = {
      'page[size]': PAGE_SIZE,
      'page[number]': fnCurrent,
      'sort[by]': 'created_at',
      'sort[direction]': 'desc',
      'filters[type][]': tabView === 'all' ? undefined : tabView,
    };

    try {
      const res = await apiRequests.get(endpoint, params, {}, abort);

      setState((prevState) => ({
        ...prevState,
        loading: false,
        data: [...[...res.data.data].reverse(), ...prevState.data],
        hasMore: res.data.data.length === PAGE_SIZE,
      }));
    } catch (error) {
      if (error.code === 'ERR_CANCELED') return;
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const loadMore = () => {
    fetchComments(current + 1);
  };

  const resetComment = () => {
    setState((prevState) => ({ ...prevState, data: [], hasMore: true, scrollToTarget: true }));
  };

  const reloadComment = () => {
    resetComment();
    fetchComments(1);
  };

  useEffect(() => {
    const abort = new AbortController();

    resetComment();
    fetchComments(1, abort);

    return () => {
      abort.abort();
    };
  }, [tabView]);

  useEffect(() => {
    if (current === 1 && data.length > 0 && onCommentsLoaded && targetCommentId) {
      const cancel = onCommentsLoaded();

      return () => {
        if (cancel) {
          cancel();
        }
      };
    }

    return () => {};
  }, [data]);

  useEffect(() => {
    if (!state.scrollToTarget || !state.data.length) {
      return;
    }

    setState((prev) => ({ ...prev, scrollToTarget: false }));

    if (targetCommentId) {
      const targetCommentEl = scrollRef.current?.getScrollElement()?.querySelector<HTMLElement>(`.comment-${targetCommentId}`);

      if (targetCommentEl) {
        const { parentElement } = targetCommentEl;

        scrollTo(
          parentElement?.classList?.contains('ant-comment-nested')
            ? (parentElement.parentElement?.parentElement?.offsetTop ?? 0) + targetCommentEl.offsetTop
            : targetCommentEl.offsetTop,
        );
      }
    } else {
      scrollToBottom();
    }
  }, [state.scrollToTarget, state.data]);

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.foundation.comment',
      callback: (event: any) => {
        if (
          (state.loading && !state.data.length) ||
          event.payload[typeKeys[type]] !== typeUuid ||
          event.payload.ref_token === localRandomUuid
        )
          return;

        if (type === 'projects' && (event.payload.task_id || event.payload.request_id)) return;

        if (event.action === 'delete') {
          setState((prev) => {
            const newData = prev.data.map((item) => {
              if (event.id === item.uuid) {
                return getCommentDeleted(item);
              }

              return {
                ...item,
                replies: item.replies.map((reply: any) => (reply.uuid === event.id ? getCommentDeleted(reply) : reply)),
              };
            });

            const removeReply = prev.replyToUuid === event.id;

            return {
              ...prev,
              data: newData,
              replyTo: removeReply ? null : prev.replyTo,
              replyToUuid: removeReply ? null : prev.replyToUuid,
            };
          });

          return;
        }

        apiRequests
          .get(`${apiRoutes.COMMENTS}/${event.id}`)
          .catch(() => {})
          .then((response) => {
            if (!response) return;

            const comment = response.data.data;

            if (event.action === 'create') {
              const scrollElement = scrollRef.current?.getScrollElement();
              const shouldScroll =
                scrollElement && scrollElement.scrollTop + scrollElement.offsetHeight >= scrollElement.scrollHeight - 50;

              if (shouldScroll) {
                setTimeout(() => scrollToBottom(), 300);
              }

              setState((prev) => {
                const newData =
                  comment.commentable_type === 'comment'
                    ? prev.data.map((item) => {
                        return comment.commentable_id === item.uuid ? { ...item, replies: [...item.replies, comment] } : item;
                      })
                    : [...prev.data, comment];

                return { ...prev, data: newData };
              });

              return;
            }

            setState((prev) => {
              const newData = prev.data.map((item) => {
                if (comment.commentable_type === 'comment' && comment.commentable_id === item.uuid) {
                  return {
                    ...item,
                    replies: item.replies.map((reply: any) => (reply.uuid === comment.uuid ? comment : reply)),
                  };
                }

                return item.uuid === comment.uuid ? comment : item;
              });

              return { ...prev, data: newData };
            });
          });
      },
    },
  });

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.foundation.upload',
      callback: (event: any) => {
        if (event.action !== 'delete') return;

        const deleteCommentImage = (comment: any) => {
          const newUploads = comment.uploads.filter((upload: any) => upload.uuid !== event.id);

          if (newUploads.length !== comment.uploads.length) {
            comment.uploads = newUploads;
            return true;
          }

          return !!comment.replies.find((reply: any) => deleteCommentImage(reply));
        };

        if (data.find((item) => deleteCommentImage(item))) {
          setState((prev) => ({ ...prev, data: [...data] }));
        }
      },
    },
  });

  return {
    state,
    commentType,
    scrollRef,
    targetCommentId,
    inputRef,
    tabView,
    setTabView,
    setCommentType,
    setComment,
    handleAddComments,
    handleUpdateComment,
    handleDeleteComment,
    handleDeleteUpload,
    handleAddReply,
    handleUpdateReply,
    handleDeleteReply,
    handleAddReaction,
    handleRemoveReaction,
    handleAddReplyReaction,
    handleRemoveReplyReaction,
    loadMore,
    setReplyTo,
    reloadComment,
  };
};

export default useComments;
