import { createSlice } from '@reduxjs/toolkit';

export const commentTemplateSlicer = createSlice({
  name: 'commentTemplates',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getCommentTemplates: () => {},
    setCommentTemplatesLoading: (state) => ({ ...state, loading: true }),
    setCommentTemplatesData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setCommentTemplatesError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setCommentTemplatesData, setCommentTemplatesError, setCommentTemplatesLoading, getCommentTemplates } =
  commentTemplateSlicer.actions;

export default commentTemplateSlicer.reducer;
