import { Modal, message } from 'antd';

const asyncErrorHandler = (error: any) => {
  // @ts-ignore
  if (window.activeVersionModal) {
    return;
  }

  const isInvalidVersionError = error?.response?.status === 400 && error?.response?.data?.message === 'invalid_api_version';

  if (isInvalidVersionError) {
    // @ts-ignore
    window.activeVersionModal = true;
    const modal = Modal.info({});
    modal.update({
      title: 'An update is available',
      okText: 'Refresh',
      cancelText: 'Close',
      content: <div>You must refresh the page to get the latest version and be able to proceed.</div>,
      closable: true,
      onOk: () => {
        // @ts-ignore
        window.activeVersionModal = false;
        window.location.reload();
      },
      onCancel: () => modal.destroy(),
    });
  } else {
    if (error?.response?.data?.message === 'existing_subtasks') {
      message.error('You must complete all subtasks before complete this one.');
      return;
    }

    if (error?.errorFields) {
      message.error('Some fields are missing.');
      return;
    }

    const errors = error?.response?.data?.errors;
    const keys = Object.keys(errors ?? {});
    if (keys.length > 0) {
      const values = keys.map((key) => errors[key]);
      message.error(values[0]);
    } else {
      message.error(error?.response?.data?.message ?? error.message ?? 'Something went wrong!');
    }
  }
};

export default asyncErrorHandler;
