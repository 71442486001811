import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import {
  getDashboardProjectLogs,
  setDashboardProjectLogsData,
  setDashboardProjectLogsError,
  setDashboardProjectLogsLoading,
} from './dashboardProjectLogs-slice';

const { get } = api;

function* getDashboardProjectLogsSagaListener(action: any) {
  try {
    yield put(setDashboardProjectLogsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.RECENT_PROJECT_LOG, action.payload);
    yield put(setDashboardProjectLogsData(res.data));
  } catch (error) {
    yield put(setDashboardProjectLogsError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardProjectLogsSaga(): Generator<StrictEffect> {
  yield takeEvery(getDashboardProjectLogs.type, getDashboardProjectLogsSagaListener);
}

export default dashboardProjectLogsSaga;
