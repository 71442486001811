import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { feedbackIntegration } from '@sentry/react';
import { Button, Dropdown, Menu } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';

interface AdditionalButtonsProps {}

const sentryFeedback = feedbackIntegration({
  colorScheme: 'system',
  autoInject: false,
});

const sentryWidget = sentryFeedback.createWidget();

sentryWidget.el.style.display = 'none';

const AdditionalButtons: FC<AdditionalButtonsProps> = () => {
  const adminLinks = [
    {
      label: 'Report bug',
      action() {
        sentryWidget.el.click();
      },
    },
    {
      label: 'Request a new resource',
      url: 'https://savageglobalmarketing.typeform.com/to/pxuyeRl2',
    },
    {
      label: 'Request new software',
      url: 'https://savageglobalmarketing.typeform.com/to/pqS014bt',
    },
    {
      label: 'Request time-off',
      url: 'https://savageglobalmarketing.typeform.com/to/yZFxyRL5',
    },
  ];

  return (
    <div style={{ position: 'fixed', right: 10, bottom: 10, zIndex: 5 }}>
      <Dropdown
        placement="bottomRight"
        trigger={[isMobile ? 'click' : 'hover']}
        overlay={
          <Menu style={{ minWidth: 200 }}>
            {adminLinks.map((el, i) => {
              return (
                <Menu.Item
                  onClick={() => {
                    if (el.action) {
                      el.action();
                    }
                  }}
                  key={i}
                >
                  {el.url ? (
                    <a href={el.url} target="_blank" rel="noreferrer">
                      {el.label}
                    </a>
                  ) : (
                    el.label
                  )}
                </Menu.Item>
              );
            })}
          </Menu>
        }
      >
        <Button type="primary" size="large" shape="circle" icon={<QuestionOutlined style={{ fontSize: 20 }} />} />
      </Dropdown>
    </div>
  );
};

export default AdditionalButtons;
