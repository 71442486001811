import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import { generateHexColor, getAvatar } from 'utils/string.utils';

interface UserAvatarProps {
  user?: { name: string; avatar?: null | string } | undefined | null;
  size?: AvatarSize | undefined;
}

const UserAvatar: FC<UserAvatarProps> = ({ user, ...props }) => {
  const currentUser = useSelector((store: any) => store.auth.user);

  const avatarObj = getAvatar(user ?? currentUser);

  return (
    <Avatar {...props} src={avatarObj?.value} style={{ background: generateHexColor(avatarObj?.value) }}>
      {avatarObj?.type === 'text' ? avatarObj?.value : undefined}
    </Avatar>
  );
};

export default UserAvatar;
