import { FC } from 'react';
import { Card, Tooltip } from 'antd';
import { CommentTemplate } from '../../types';
import RichTextEditor from '../RichTextEditor';

interface TemplateCommentCardProps {
  template: CommentTemplate;
  onClick?: (template: CommentTemplate) => void;
}

const CardTooltip = ({ template }: { template: CommentTemplate }) => {
  return (
    <div>
      <Card title={template.title} size="small" bodyStyle={{ overflowY: 'auto', maxHeight: 300 }}>
        <RichTextEditor className="comment-styles" initialValue={template.content} readonly noContainer />
      </Card>
    </div>
  );
};

const TemplateCommentCard: FC<TemplateCommentCardProps> = ({ template, onClick }) => {
  return (
    <Tooltip
      destroyTooltipOnHide
      title={<CardTooltip template={template} />}
      color="#fff"
      overlayInnerStyle={{ color: '#000000', padding: 0 }}
    >
      <div
        style={{
          whiteSpace: 'nowrap',
          background: '#e4f2ff',
          padding: '3px 10px',
          border: '1px solid #d4d4d4',
          cursor: 'pointer',
        }}
        key={template.uuid}
        onClick={() => onClick?.(template)}
      >
        {template.title}
      </div>
    </Tooltip>
  );
};

export default TemplateCommentCard;
