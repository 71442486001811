import React, { FC } from 'react';

import { Layout } from 'antd';

import style from './actionbarLayout.module.less';

const { Content } = Layout;

interface ActionBarLayoutProps {}

const ActionBarLayout: FC<ActionBarLayoutProps> = ({ children }) => {
  return <Content className={`page-scrollbar ${style.content}`}>{children}</Content>;
};

export default ActionBarLayout;
