import Quill from 'quill';
import type Toolbar from 'quill/modules/toolbar';
import CustomTooltip from './CustomTooltip';

const SnowTheme = Quill.import('themes/snow');

export default class CustomTheme extends SnowTheme {
  extendToolbar(toolbar: Toolbar) {
    super.extendToolbar(toolbar);

    this.tooltip.root.remove();

    this.tooltip = new CustomTooltip(this.quill, this.options.bounds);
  }
}
