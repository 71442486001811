import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const customersSlice = createSlice({
  name: 'customerSelect',
  initialState,
  reducers: {
    getCustomerSelect: () => {},
    setCustomerSelectLoading: (state) => ({ ...state, loading: true }),
    setCustomerSelectData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setCustomerSelectError: (state, action) => ({ ...state, loading: false, error: action.payload }),
    clearCustomerSelectData: () => initialState,
  },
});

export const {
  getCustomerSelect,
  setCustomerSelectLoading,
  setCustomerSelectData,
  setCustomerSelectError,
  clearCustomerSelectData,
} = customersSlice.actions;

export default customersSlice.reducer;
