/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { Form, Select, Typography } from 'antd';
import uniqBy from 'lodash/uniqBy';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

const { Text } = Typography;
interface SelectSearchProps {
  api: string;
  labelKey?: string;
  valueKey?: string;
  name?: string;
  label?: string;
  rules?: any;
  className?: any;
  initialOptions?: any[];
  initialValue?: any;
  startingOptions?: any[];
  initialOption?: any;
  defaultFilter?: any;
  labelCol?: any;
  mode?: any;
  style?: CSSProperties;
  formItemClassName?: string;
  placeholder?: string;
  render?: any;
  onChange?: any;
  defaultParams?: any;
  size?: string;
  disabled?: boolean;
  searchByLabelKey?: boolean;
  onLoad?: any;
  allowClear?: boolean;
  searchKey?: any;
  filterBeforeRender?: any;
  renderOptionProps?: any;
  onBlur?: any;
  customSearchField?: any;
  maxLength?: any;
  noMargin?: boolean;
  noResponsive?: boolean;
  noStyle?: boolean;
}

const SelectSearch: FC<SelectSearchProps> = ({
  labelKey = 'name',
  valueKey = 'uuid',
  name,
  label,
  rules,
  api,
  className,
  initialOptions = [],
  initialValue,
  initialOption,
  defaultFilter,
  labelCol,
  mode,
  style,
  formItemClassName,
  startingOptions = [],
  filterBeforeRender,
  placeholder,
  customSearchField,
  render,
  size,
  onChange,
  defaultParams = {},
  disabled,
  searchByLabelKey,
  searchKey,
  onLoad,
  allowClear,
  renderOptionProps,
  onBlur,
  noMargin = false,
  noResponsive,
  noStyle,
}) => {
  const singleOption = [];
  if (initialOption) {
    singleOption.push(initialOption);
  }

  const [state, setState] = useState({
    loading: false,
    searching: false,
    data: [
      ...[...startingOptions, ...singleOption, ...initialOptions].map((el) => ({
        ...el,
        value_copy: el?.value_copy ?? el?.value,
        label: el[labelKey],
        value: el[valueKey],
      })),
    ] as any,
    search: null as any,
  });

  const { data, loading } = state;
  const [search, setSearch] = useState(null as any);

  useEffect(() => {
    let cancelPromise = false;

    const fetchData = async () => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const params: any = { ...defaultParams };
        if (search) {
          if (searchByLabelKey || searchKey) {
            params[searchKey ?? labelKey] = search;
          } else {
            params['search_term[0][field]'] = customSearchField ?? labelKey;
            params['search_term[0][rule]'] = 'contains';
            params['search_term[0][value]'] = search;
          }
        }

        if (defaultFilter && defaultFilter?.filterBy && defaultFilter?.value) {
          params[`filters[${defaultFilter?.filterBy}][]`] = defaultFilter?.value;
        }

        const res = await apiRequests.get(api, params);

        if (cancelPromise) return;

        const getUniqData = (prevState: any) => {
          const newData = res?.data?.data?.map((el: any) => ({
            ...el,
            value_copy: el?.value_copy ?? el?.value,
            label: el[labelKey],
            value: el[valueKey],
          }));
          const uniqData = uniqBy(
            [
              ...startingOptions.map((el) => ({
                ...el,
                value_copy: el?.value_copy ?? el?.value,
                label: el[labelKey],
                value: el[valueKey],
              })),
              ...newData,
              ...prevState.data,
            ],
            'value',
          );

          if (onLoad) {
            onLoad(uniqData);
          }

          return uniqData;
        };

        setState((prevState) => ({
          ...prevState,
          loading: false,
          data: getUniqData(prevState),
        }));
      } catch (error) {
        asyncErrorHandler(error);
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    };
    fetchData();

    return () => {
      cancelPromise = true;
    };
  }, [search, defaultFilter?.filterBy, defaultFilter?.value]);

  return (
    <Form.Item
      style={{
        // @ts-ignore
        position: 'relative !important',
        margin: noMargin ? 0 : undefined,
      }}
      className={formItemClassName}
      label={label}
      labelCol={labelCol}
      name={name}
      initialValue={initialValue}
      rules={rules}
      noStyle={noStyle}
    >
      <Select
        maxLength={1}
        onBlur={onBlur}
        allowClear={allowClear}
        dropdownStyle={{ position: 'fixed' }}
        className={className}
        disabled={disabled}
        filterOption={(input, el: any) => el?.label?.toLowerCase().includes(input?.toLowerCase())}
        loading={loading}
        maxTagCount={!noResponsive ? 'responsive' : undefined}
        mode={mode}
        onChange={(value) => {
          const res = Array.isArray(value)
            ? data?.filter((el: any) => value.includes(el.value))
            : data?.find((el: any) => el.value === value);
          if (onChange) onChange(res);
        }}
        onSearch={(value) => {
          setSearch(value);
        }}
        onSelect={() => {
          setSearch('');
        }}
        placeholder={placeholder}
        searchValue={search}
        showArrow
        showSearch
        size={size as any}
        style={style}
      >
        {(filterBeforeRender ? filterBeforeRender(data) : data)?.map((el: any) => (
          <Select.Option {...(renderOptionProps ? renderOptionProps(el) : {})} key={el.value} label={el.label} value={el.value}>
            <Text strong={el.bold}>{render ? render(el) : el.label}</Text>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectSearch;
