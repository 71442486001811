import { createSlice } from '@reduxjs/toolkit';
import { ListResponse } from 'types';

import { InboxResponse } from '../types';

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState: {
    loading: false,
    data: null as ListResponse<InboxResponse> | null,
    error: null,
    unreadCounter: null as null | number,
  },
  reducers: {
    getInbox: () => {},
    fetchUnreadCounter: () => {},
    setInboxLoading: (state) => ({ ...state, loading: true }),
    setInboxData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setInboxError: (state, action) => ({ ...state, loading: false, error: action.payload }),
    setUnreadCounter: (state, { payload }: { payload: number; type: string }) => ({ ...state, unreadCounter: payload }),
    addUnreadCounter: (state, { payload }: { payload: number; type: string }) => ({
      ...state,
      unreadCounter: state.unreadCounter !== null ? state.unreadCounter + payload : null,
    }),
  },
});

export const { setInboxData, setInboxError, setInboxLoading, getInbox, fetchUnreadCounter, setUnreadCounter, addUnreadCounter } =
  inboxSlice.actions;

export default inboxSlice.reducer;
