import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, select, takeLatest } from 'redux-saga/effects';
import api from 'utils/api';
import { AxiosResponse } from 'utils/interface';

import {
  getActiveLogs,
  setActiveLogsActiveData,
  setActiveLogsData,
  setActiveLogsError,
  setActiveLogsLoading,
} from './activeLogs-slice';

/**
 * worker saga
 */
const { get } = api;

export function* getActiveLogsSagaListener(): any {
  const user = yield select((store) => store.auth.user);

  try {
    yield put(setActiveLogsLoading());

    const res: AxiosResponse = yield call(get, `${apiRoutes.TIME_LOG}`, {
      'filters[user.uuid][]': user?.uuid,
      'filters[status]': ['running', 'paused'],
    });

    const active = res.data.data.find((item: any) => item.status === 'running');

    yield put(setActiveLogsActiveData(active));
    yield put(setActiveLogsData(res.data.data));
  } catch (e) {
    yield put(setActiveLogsError(e));
  }
}

/**
 * watcher saga
 */
function* activeLogsSaga(): Generator<StrictEffect> {
  yield takeLatest(getActiveLogs.type, getActiveLogsSagaListener);
}

export default activeLogsSaga;
