import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getProductTypes, setProductTypesData, setProductTypesError, setProductTypesLoading } from './productTypes-slice';

const { get } = api;

function* getProductTypesSagaListener(action: any) {
  try {
    yield put(setProductTypesLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PRODUCT_TYPES, action.payload);
    yield put(setProductTypesData(res.data));
  } catch (error) {
    yield put(setProductTypesError(error));
    asyncErrorHandler(error);
  }
}

function* productTypesSaga(): Generator<StrictEffect> {
  yield takeEvery(getProductTypes.type, getProductTypesSagaListener);
}

export default productTypesSaga;
