const PERMISSION_KEYS = {
  ROLES_READ: 'role_read',
  ROLES_CREATE: 'role_create',
  ROLES_UPDATE: 'role_update',
  ROLES_DELETE: 'role_destroy',

  USERS_READ: 'user_read',
  USERS_CREATE: 'user_create',
  USERS_UPDATE: 'user_update',
  USERS_DELETE: 'user_destroy',

  GROUPS_READ: 'group_read',
  GROUPS_CREATE: 'group_create',
  GROUPS_UPDATE: 'group_update',
  GROUPS_DELETE: 'group_destroy',

  LOGS_READ: 'logs_read',
  EMAIL_LOG_READ: 'email_log_read',
  RESOURCES_READ: 'resources_read',
  CMS_READ: 'resources_read',
  DASHBOARD_READ: 'resources_read',

  ONBOARDING_READ: 'onboarding_read',
  ONBOARDING_UPDATE: 'onboarding_update',

  CUSTOMERS_READ: 'customer_read',
  CUSTOMERS_CREATE: 'customer_create',
  CUSTOMERS_UPDATE: 'customer_update',
  CUSTOMERS_DELETE: 'customer_destroy',

  CONTACTS_READ: 'contact_read',
  CONTACTS_CREATE: 'contact_create',
  CONTACTS_UPDATE: 'contact_update',
  CONTACTS_DELETE: 'contact_destroy',

  REQUEST_READ: 'request_read',
  REQUEST_CREATE: 'request_create',
  REQUEST_UPDATE: 'request_update',
  REQUEST_DELETE: 'request_destroy',

  PROJECTS_READ: 'project_read',
  PROJECTS_CREATE: 'project_create',
  PROJECTS_UPDATE: 'project_update',
  PROJECTS_DELETE: 'project_destroy',

  TASKS_READ: 'task_read',
  TASKS_CREATE: 'task_create',
  TASKS_UPDATE: 'task_update',
  TASKS_DELETE: 'task_destroy',

  CHARGE_READ: 'charge_read',
  CHARGE_CREATE: 'charge_create',
  CHARGE_UPDATE: 'charge_update',
  CHARGE_DELETE: 'charge_destroy',

  INTEGRATIONS_READ: 'integration_read',
  INTEGRATIONS_CREATE: 'integration_create',
  INTEGRATIONS_UPDATE: 'integration_update',
  INTEGRATIONS_DELETE: 'integration_destroy',

  SERVICES_READ: 'service_read',
  SERVICES_CREATE: 'service_create',
  SERVICES_UPDATE: 'service_update',
  SERVICES_DELETE: 'service_destroy',

  ITEM_READ: 'product_read',
  ITEM_CREATE: 'product_create',
  ITEM_UPDATE: 'product_update',
  ITEM_DELETE: 'product_destroy',

  ITEM_TYPE_READ: 'product_type_read',
  ITEM_TYPE_CREATE: 'product_type_create',
  ITEM_TYPE_UPDATE: 'product_type_update',
  ITEM_TYPE_DELETE: 'product_type_destroy',

  POST_READ: 'post_read',
  POST_CREATE: 'post_create',
  POST_UPDATE: 'post_update',
  POST_DELETE: 'post_destroy',

  TIMELOG_READ: 'timelog_read',
  TIMELOG_CREATE: 'timelog_create',
  TIMELOG_UPDATE: 'timelog_update',
  TIMELOG_DELETE: 'timelog_destroy',

  COMMENTS_READ: 'comment_read',
  COMMENTS_CREATE: 'comment_create',
  COMMENTS_UPDATE: 'comment_update',
  COMMENTS_DELETE: 'comment_destroy',

  COMMENT_TEMPLATES_READ: 'comment_template_read',
  COMMENT_TEMPLATES_CREATE: 'comment_template_create',
  COMMENT_TEMPLATES_UPDATE: 'comment_template_update',
  COMMENT_TEMPLATES_DELETE: 'comment_template_destroy',

  FORM_READ: 'form_read',
  FORM_CREATE: 'form_create',
  FORM_UPDATE: 'form_update',
  FORM_DELETE: 'form_destroy',
};

const superUsers = [
  'christopher@savageglobalent.com',
  'santiago@savageglobalent.com',
  'marcelo@savageglobalent.com',
  'lucas@savageglobalent.com',
];

export const checkIsSuperUser = (email: string | null | undefined) => {
  if (!email) return false;

  return !!superUsers.find((el) => email === el);
};

export type PermissionKey = keyof typeof PERMISSION_KEYS;

export { PERMISSION_KEYS };
