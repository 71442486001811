import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import SimpleBar from 'simplebar-react';
import CountRequestBadge from 'components/CountRequestBadge';
import CountUnreadInboxBadge from 'components/CountUnreadInboxBadge';
import { checkHasPermission, checkHasRole } from 'utils/auth';
import { getRouteConfig, MenuItemBaseLinkType } from './config';
import useSidebar from './useSidebar';

const { Sider } = Layout;

interface SidebarProps {
  activeRoute?: MenuItemBaseLinkType;
}

const Sidebar: FC<SidebarProps> = ({ activeRoute }) => {
  const { showAnalytic, user, isSuperUser, isAdmin } = useSidebar();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [onBreakpoint, setOnBreakpoint] = useState(false);

  const config = getRouteConfig(isAdmin);

  return (
    <Sider
      breakpoint="sm"
      collapsedWidth="0"
      color="#0044CC"
      collapsed={collapsed}
      onBreakpoint={(broken) => {
        setOnBreakpoint(broken);
      }}
      onCollapse={(val) => {
        setCollapsed(val);
      }}
      className="app-sidebar"
    >
      <SimpleBar style={{ maxHeight: 'calc(100vh - 68px)' }}>
        <Menu
          style={{ backgroundColor: '#0044CC' }}
          mode="inline"
          selectedKeys={activeRoute && 'path' in activeRoute ? [activeRoute.path] : undefined}
          theme="dark"
        >
          {config.map((item) => {
            if (item.label === 'Analytics' && !showAnalytic) {
              return null;
            }

            const allowed =
              (!item.permission || (item.permission && checkHasPermission(user, item.permission))) &&
              (!item.role || (item.role && checkHasRole(user, item.role)));

            if (!allowed || (item.needsSuperUser && !isSuperUser)) {
              return null;
            }

            if ('submenu' in item) {
              const res = item.submenu.find((subItem) => checkHasPermission(user, subItem.permission));

              if (!res) return null;

              return (
                <Menu.SubMenu icon={<item.icon />} title={item.label} key={`${item.key}-submenu`}>
                  {item.submenu?.map((subItem) => {
                    if (
                      (subItem.needsSuperUser && !isSuperUser) ||
                      (subItem.permission && !checkHasPermission(user, subItem.permission)) ||
                      (subItem.role && !checkHasRole(user, subItem.role))
                    ) {
                      return null;
                    }

                    return (
                      <Menu.Item
                        onClick={() => {
                          if (onBreakpoint) {
                            setCollapsed(true);
                          }

                          if (subItem.path) {
                            history.push(subItem.path);
                          }
                        }}
                        key={subItem.path}
                      >
                        {subItem?.label}
                      </Menu.Item>
                    );
                  })}
                </Menu.SubMenu>
              );
            }

            return (
              <Menu.Item
                icon={<item.icon />}
                key={item.path}
                onClick={() => {
                  if (onBreakpoint) {
                    setCollapsed(true);
                  }

                  if (item.path) {
                    history.push(item.path);
                  }
                }}
              >
                {item.label}
                {item.label === 'Requests' && <CountRequestBadge />}
                {item.label === 'Inbox' && <CountUnreadInboxBadge />}
              </Menu.Item>
            );
          })}
        </Menu>
      </SimpleBar>
    </Sider>
  );
};

export default Sidebar;
