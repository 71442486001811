import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { updateAuthData } from 'store/container/auth/auth-slice';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import { UserResponse } from 'types';

const { Title } = Typography;

interface SmsSignUpModalProps {
  user: UserResponse;
  actionApi?: boolean;
  onSubmit?: ({ phone }: { phone: string }) => void;
  onCancel?: () => void;
  onClose?: () => void;
}

const SmsSignUpModal: FC<SmsSignUpModalProps> = ({ user, actionApi = true, onSubmit, onCancel, onClose }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const submitHandler = async ({ phone }: any) => {
    onSubmit?.({ phone });

    if (!actionApi) {
      return;
    }

    setLoading(true);

    try {
      await apiRequests.put(`${apiRoutes.CONTACTS}/${user?.contact?.uuid}`, {
        phone,
        preferences: {
          sms_notifications: '1',
        },
      });

      dispatch(
        updateAuthData({
          ...user,
          contact: {
            ...user.contact,
            phone,
          },
          preferences: {
            ...user.preferences,
            sms_notifications: '1',
          },
        }),
      );

      setOpen(false);
    } catch (error) {
      asyncErrorHandler(error);
      setLoading(false);
    }
  };

  const onCancelHandler = () => {
    if (actionApi && user.preferences.sms_notifications === null) {
      apiRequests.put(`${apiRoutes.CONTACTS}/${user?.contact?.uuid}`, {
        preferences: {
          sms_notifications: '0',
        },
      });

      dispatch(
        updateAuthData({
          ...user,
          preferences: {
            ...user.preferences,
            sms_notifications: '0',
          },
        }),
      );
    }

    onCancel?.();

    setOpen(false);
  };

  return (
    <Modal visible={open} zIndex={1070} footer={null} onCancel={onCancelHandler} afterClose={() => onClose?.()}>
      <Title level={3} style={{ textAlign: 'center', paddingBottom: '0.5rem' }}>
        Get notification via SMS
      </Title>

      <div style={{ margin: '0 auto', width: '16rem' }}>
        <Form onFinish={submitHandler}>
          <Form.Item
            name="phone"
            rules={[{ required: true, message: 'Please enter the phone number' }]}
            initialValue={user.contact?.phone}
          >
            <Input size="large" placeholder="Phone number" prefix={<PhoneOutlined />} />
          </Form.Item>

          <Space direction="vertical" className="w-full" size={12}>
            <Button htmlType="submit" type="primary" loading={loading} block>
              Sign up for SMS
            </Button>

            <Button type="primary" ghost block onClick={onCancelHandler}>
              No thanks
            </Button>
          </Space>
        </Form>
      </div>

      <div style={{ marginTop: '2.5rem' }}>
        By submitting this form and signing up for texts, you consent to receive text messages from Lionshare. Consent is not a
        condition of purchase. Msg & data rates may apply. Msg frequency varies. Unsubscribe at any time by replying STOP or
        disabling SMS notifications in the notification options in your profile on Lionshare.
      </div>
    </Modal>
  );
};

export default SmsSignUpModal;
