import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import type { RootState } from 'store';

const CountUnreadInboxBadge = () => {
  const unreadCounter = useSelector((store: RootState) => store.inbox.unreadCounter);

  return <Badge count={unreadCounter ?? 0} />;
};

export default CountUnreadInboxBadge;
