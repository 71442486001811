import { FC } from 'react';
import {
  BarChartOutlined,
  TeamOutlined,
  UserOutlined,
  ClockCircleOutlined,
  SettingOutlined,
  FormOutlined,
  CalendarOutlined,
  HomeOutlined,
  IdcardOutlined,
  ProfileOutlined,
  FolderOpenOutlined,
  ExceptionOutlined,
  FolderViewOutlined,
} from '@ant-design/icons';
import appRoutes from 'config/appRoutes';
import { PermissionKey } from 'config/permissions';

interface MenuItemBaseType {
  label: string;
  permission?: PermissionKey;
  role?: string;
  needsSuperUser?: boolean;
}

export interface MenuItemBaseLinkType extends MenuItemBaseType {
  path: string;
}

export interface MenuItemLinkType extends MenuItemBaseLinkType {
  icon: FC;
}

export interface MenuItemParentType extends MenuItemBaseType {
  icon: FC;
  submenu: MenuItemBaseLinkType[];
  key: string;
}

export type MenuItemType = MenuItemLinkType | MenuItemParentType;

export const regularUserConfig: MenuItemType[] = [
  {
    label: 'Dashboard',
    path: appRoutes.DASHBOARD,
    icon: HomeOutlined,
    permission: 'DASHBOARD_READ',
  },
  {
    path: appRoutes.PERSONAL_TASKS,
    label: 'Personal',
    icon: ProfileOutlined,
    permission: 'TASKS_READ',
  },
  {
    label: 'Requests',
    path: appRoutes.REQUESTS,
    icon: ExceptionOutlined,
    permission: 'REQUEST_READ',
  },
  {
    label: 'Inbox',
    path: appRoutes.INBOX,
    icon: IdcardOutlined,
    permission: 'COMMENTS_READ',
  },
  {
    label: 'My calendar',
    path: appRoutes.CALENDAR,
    icon: CalendarOutlined,
    permission: 'INTEGRATIONS_READ',
  },
  {
    label: 'Tasks',
    path: appRoutes.TASKS,
    icon: ProfileOutlined,
    permission: 'TASKS_READ',
  },

  {
    label: 'Projects',
    path: appRoutes.PROJECTS,
    icon: FolderOpenOutlined,
    permission: 'PROJECTS_READ',
  },
  {
    label: 'Project logs',
    path: appRoutes.PROJECT_LOGS,
    icon: FolderViewOutlined,
    permission: 'PROJECTS_READ',
  },
  {
    label: 'Timelog',
    path: appRoutes.TIME_LOG_REPORT,
    icon: ClockCircleOutlined,
    permission: 'TIMELOG_READ',
  },
  {
    label: 'Clients',
    path: appRoutes.CLIENTS,
    icon: TeamOutlined,
    permission: 'CUSTOMERS_READ',
  },
  {
    label: 'Users',
    path: appRoutes.CONTACTS,
    icon: TeamOutlined,
    permission: 'CONTACTS_READ',
  },

  {
    label: 'Forms',
    path: appRoutes.FORMS,
    icon: FormOutlined,
    permission: 'FORM_READ',
  },
  {
    label: 'Resources',
    path: appRoutes.CMS,
    icon: ProfileOutlined,
    permission: 'CMS_READ',
  },

  {
    label: 'Admin',
    icon: SettingOutlined,
    key: 'admin',
    submenu: [
      {
        label: 'Company info',
        path: appRoutes.ADMINISTRATION,
        permission: 'INTEGRATIONS_READ',
        needsSuperUser: true,
      },

      {
        label: 'Activity log',
        path: appRoutes.ACTIVITY_LOG,
        permission: 'PROJECTS_READ',
      },
      {
        label: 'Time report',
        path: appRoutes.PROJECT_TiME_REPORT,
        permission: 'PROJECTS_READ',
      },
      {
        path: appRoutes.CONTENT,
        label: 'Content',
        permission: 'POST_READ',
      },
      {
        path: appRoutes.PRODUCTS,
        label: 'Items',
        permission: 'ITEM_READ',
        needsSuperUser: true,
      },
      {
        path: appRoutes.PRODUCT_TYPES,
        label: 'Item types',
        permission: 'ITEM_TYPE_READ',
        needsSuperUser: true,
      },
      {
        path: appRoutes.TASK_CATEGORY,
        label: 'Task categories',
        permission: 'ITEM_TYPE_READ',
        needsSuperUser: true,
      },
      {
        path: appRoutes.GROUPS,
        label: 'Groups',
        permission: 'GROUPS_READ',
        needsSuperUser: true,
      },
      {
        label: 'Roles',
        path: appRoutes.ROLES,
        permission: 'ROLES_READ',
        needsSuperUser: true,
      },
      {
        label: 'Logs',
        path: appRoutes.LOGS,
        permission: 'EMAIL_LOG_READ',
      },

      {
        label: 'Integrations',
        path: appRoutes.INTEGRATIONS,
        permission: 'INTEGRATIONS_READ',
      },

      {
        label: 'Templates',
        path: appRoutes.COMMENT_TEMPLATES,
        permission: 'COMMENT_TEMPLATES_READ',
      },
    ],
  },
];

export const contactUserConfig: MenuItemType[] = [
  {
    label: 'Dashboard',
    path: appRoutes.DASHBOARD,
    icon: HomeOutlined,
  },
  {
    path: appRoutes.ACCOUNT,
    label: 'Account',
    icon: UserOutlined,
  },
  {
    label: 'Analytics',
    icon: BarChartOutlined,
    permission: 'PROJECTS_READ',
    path: appRoutes.ANALYTICS,
  },
  {
    path: appRoutes.CONTACTS,
    label: 'Users',
    icon: TeamOutlined,
    permission: 'CONTACTS_READ',
  },
  {
    path: appRoutes.PROJECTS,
    label: 'Projects',
    icon: FolderOpenOutlined,
    permission: 'PROJECTS_READ',
  },
  {
    path: appRoutes.TASKS,
    label: 'Tasks',
    icon: ProfileOutlined,
    permission: 'TASKS_READ',
  },

  {
    label: 'Resources',
    path: appRoutes.CMS,
    icon: ProfileOutlined,
  },
];

const allRoutes: MenuItemBaseLinkType[] = [];

[...regularUserConfig, ...contactUserConfig].forEach((el) => {
  if ('submenu' in el) {
    el.submenu.forEach((sub) => {
      allRoutes.push(sub);
    });
  } else {
    allRoutes.push(el);
  }
});

export const getRouteConfig = (isAdmin: boolean) => {
  return isAdmin ? regularUserConfig : contactUserConfig;
};

export const getAllRoutes = () => {
  return allRoutes;
};
