import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';

export const logoutUser = () => {
  localStorage.clear();
  window.location.pathname = '/login';
};

export const sendResetMail = (data: any): Promise<AxiosResponse> => apiRequests.post(apiRoutes.RESET_EMAIL, data);

export const updatePassword = (data: any): Promise<AxiosResponse> => apiRequests.post(apiRoutes.RESET_PASSWORD, data);
