/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const globalLogSlice = createSlice({
  name: 'globalLog',
  initialState: {
    trigger: 0,
  },
  reducers: {
    setGlobalLogTrigger: (state) => ({ ...state, trigger: state.trigger + 1 }),
  },
});

export const { setGlobalLogTrigger } = globalLogSlice.actions;

export default globalLogSlice.reducer;
