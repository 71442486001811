import { put, takeLatest } from 'redux-saga/effects';
import { createAppTableSaga, createAppTableStore } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';

export const projectWorkLogSlice = createAppTableStore({
  name: 'tableProjectWorkLogs' as const,
  url: apiRoutes.PROJECTS_WORK_LOGS,
  initialState: {
    params: {
      sort: { column: 'date', order: 'descend' },
    },
    fixedParams: { 'page[size]': 1000 },
    pagination: null,
    totalTime: null as null | number,
  },
  reducers: {
    setTotalTime: (state, { payload }: { payload: number; type: string }) => {
      return {
        ...state,
        totalTime: payload,
      };
    },
  },
});

function* handleApiResponseSagaListener({ payload }: { payload: any }) {
  yield put(projectWorkLogSlice.actions.setTotalTime(parseInt(payload.total_time, 10)));
}

export const projectWorkLogsSaga = createAppTableSaga(projectWorkLogSlice, function* customGenerator() {
  yield takeLatest(projectWorkLogSlice.actions.handleApiResponse, handleApiResponseSagaListener);
});

export const projectWorkLogsReducer = projectWorkLogSlice.reducer;
