/* eslint-disable @typescript-eslint/no-unused-vars */
import { CaseReducer, createSlice, PayloadAction, Slice, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { call, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';
import type { PartialDeep } from 'type-fest';
import type { AxiosResponse } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import combineReducers from 'store/combine-reducers';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import api from 'utils/api';

const { get, put: putApi } = api;

export interface AppTableFilter {
  value: string | number | boolean;
  label?: string;
  extra?: unknown;
}

export interface AppTableSearchFilter {
  rule: string;
  field: string;
  index: number;
}

export type AppTableInitialState = {
  loading: boolean;
  data: Record<string, any>[];
  error: unknown | null;
  pagination: {
    current: number;
    total: number;
    pageSize: number;
  } | null;
  params: {
    filters: Record<string, AppTableFilter | AppTableFilter[]>;
    sort: { column: string; order: 'ascend' | 'descend' } | null;
    search: string;
  };
  fixedParams: Record<string, string | number | boolean | (string | number | boolean)[]>;
  paramFilterFields: Record<string, string>;
  searchFilters: Record<string, AppTableSearchFilter>;
  searchField: string | null;
  searchKey: string;
  selectedRows: (string | number)[];
  selectedAllPages: boolean;
  ignoreFetch: boolean;
  url: string;
  updateUrl?: string;
  serverControl?: boolean;
  tabKey?: string;
  apiParamsHandler?: (
    params: Record<string, string | number | boolean | (string | number | boolean)[]>,
    state: AppTableInitialState,
  ) => Record<string, string | number | boolean | (string | number | boolean)[]>;
};

export interface AppTableInitialReducer<TState> extends SliceCaseReducers<TState> {
  fetch: CaseReducer<TState, PayloadAction<Record<string, string | number | (string | number)[]> | undefined>>;
  updateRow: CaseReducer<TState, PayloadAction<{ uuid: string; data: Record<string, unknown>; rowKey?: string }>>;
  handleApiResponse: CaseReducer<TState, PayloadAction<any>>;
  setError: CaseReducer<TState, PayloadAction<unknown>>;
  setLoading: CaseReducer<TState, PayloadAction<boolean>>;
  setIgnoreFetch: CaseReducer<TState, PayloadAction<boolean>>;
  setSort: CaseReducer<TState, PayloadAction<{ column: string; order: 'ascend' | 'descend' } | null>>;
  setPagination: CaseReducer<TState, PayloadAction<{ pageSize: number; current: number }>>;
  setSearch: CaseReducer<TState, PayloadAction<string>>;
  setFilter: CaseReducer<TState, PayloadAction<Record<string, undefined | AppTableFilter | AppTableFilter[]>>>;
  resetFilters: CaseReducer<TState, PayloadAction<string | undefined>>;
  setInitialFilters: CaseReducer<TState, PayloadAction<Record<string, undefined | AppTableFilter | AppTableFilter[]>>>;
  setFixedParams: CaseReducer<TState, PayloadAction<Record<string, undefined | string | number | boolean | (string | number)[]>>>;
  setTotal: CaseReducer<TState, PayloadAction<number>>;
  setTabKey: CaseReducer<TState, PayloadAction<string | undefined>>;
  setSelectedRows: CaseReducer<TState, PayloadAction<(string | number)[]>>;
  toggleSelectedAllPages: CaseReducer<TState, PayloadAction<{ rowKey: string }>>;
  setApiParamsHandler: CaseReducer<TState, PayloadAction<AppTableInitialState['apiParamsHandler']>>;
  clearData: CaseReducer<TState, PayloadAction>;
  restoreInitialState: CaseReducer<TState, PayloadAction>;
}

export type AppTableSlice<TState extends Record<string, any> = any> = Slice<
  AppTableInitialState & TState,
  AppTableInitialReducer<AppTableInitialState & TState>,
  keyof typeof combineReducers
>;

function dataSortFn(data: Record<string, any>[], sort: { column: string; order: 'ascend' | 'descend' } | null) {
  if (!sort) {
    return data;
  }

  const { column } = sort;
  const isAscend = sort.order === 'ascend';

  const funDataSort = (a: any, b: any) => {
    if (a[column] < b[column]) {
      return isAscend ? -1 : 1;
    }
    if (a[column] > b[column]) {
      return isAscend ? 1 : -1;
    }
    return 0;
  };

  return data.sort(funDataSort);
}

export function createAppTableStore<
  T extends PartialDeep<AppTableInitialState> & Record<string, any>,
  TReducers extends SliceCaseReducers<AppTableInitialState & T>,
  Name extends string = string,
>({
  name,
  initialState,
  reducers: extraReducers = {} as ValidateSliceCaseReducers<AppTableInitialState & T, TReducers>,
  url,
  transformData,
}: {
  name: Name;
  initialState?: T;
  reducers?: ValidateSliceCaseReducers<AppTableInitialState & T, TReducers>;
  url: string;
  transformData?: (data: Record<string, any>[]) => Record<string, any>[];
}) {
  const defaultInitialState: AppTableInitialState = {
    loading: false,
    data: [],
    error: null,
    pagination: {
      current: 1,
      total: 0,
      pageSize: 50,
    },
    params: {
      filters: {},
      sort: null,
      search: '',
    },
    fixedParams: {},
    paramFilterFields: {},
    searchFilters: {},
    searchField: null,
    searchKey: 'search_term[0]',
    selectedRows: [],
    selectedAllPages: false,
    ignoreFetch: false,
    serverControl: true,
    url,
  };

  const tableInitialState = merge(defaultInitialState, initialState);

  const reducers: AppTableInitialReducer<AppTableInitialState & T> = {
    fetch: (state) => state,
    updateRow: (state, { payload }) => {
      const newData = [...state.data];

      const index = newData.findIndex((item) => item[payload.rowKey || 'uuid'] === payload.uuid);

      newData[index] = { ...newData[index], ...payload.data };

      return { ...state, data: newData };
    },
    handleApiResponse: (state, action) => {
      const data = transformData ? transformData(action.payload.data) : action.payload.data;

      return {
        ...state,
        error: null,
        loading: false,
        data: state.serverControl ? data : dataSortFn(data, state.params.sort),
        pagination: state.pagination
          ? {
              ...state.pagination,
              total: state.serverControl ? action.payload.meta.total : data.length,
              current: state.serverControl ? action.payload.meta.current_page : state.pagination.current,
            }
          : undefined,
      };
    },
    setError: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loading: false,
        data: [],
      };
    },
    setLoading: (state, { payload }) => {
      return { ...state, error: null, loading: payload };
    },
    setIgnoreFetch: (state, { payload }) => {
      return { ...state, error: null, ignoreFetch: payload };
    },
    setSort: (state, { payload }) => {
      return {
        ...state,
        data: state.serverControl ? state.data : dataSortFn([...state.data], payload),
        params: {
          ...state.params,
          sort: payload,
        },
      };
    },
    setPagination: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.payload.current,
          pageSize: action.payload.pageSize,
        },
      };
    },
    setSearch: (state, action) => {
      return {
        ...state,
        params: {
          ...state.params,
          search: action.payload,
        },
      };
    },
    setFilter: (state, { payload }) => {
      const newState = {
        ...state,
        params: {
          ...state.params,
          filters: {
            ...state.params.filters,
          },
        },
      };

      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const key in payload) {
        const value = payload[key];
        if (value === undefined) {
          delete newState.params.filters[key];
        } else {
          newState.params.filters[key] = value;
        }
      }

      return newState;
    },
    resetFilters: (state, action) => {
      const newState = {
        ...state,
        params: {
          ...state.params,
          filters: {
            ...state.params.filters,
          },
        },
      };

      if (action.payload) {
        const initialValue = tableInitialState.params.filters[action.payload];

        if (initialValue !== undefined) {
          newState.params.filters[action.payload] = cloneDeep(initialValue);
        }
      } else {
        newState.params.filters = cloneDeep(tableInitialState.params.filters);
      }

      return newState;
    },
    setInitialFilters: (state, { payload }) => {
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const key in payload) {
        const value = payload[key];
        if (value === undefined) {
          delete tableInitialState.params.filters[key];
        } else {
          tableInitialState.params.filters[key] = value;
        }
      }

      return state;
    },
    setFixedParams: (state, { payload }) => {
      const newState = {
        ...state,
        fixedParams: {
          ...state.fixedParams,
        },
      };

      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const key in payload) {
        const value = payload[key];
        if (value === undefined) {
          delete newState.fixedParams[key];
        } else {
          newState.fixedParams[key] = value;
        }
      }

      return newState;
    },
    setTotal: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          total: action.payload,
        },
      };
    },
    setTabKey: (state, action) => {
      return {
        ...state,
        tabKey: action.payload,
      };
    },
    setSelectedRows: (state, action) => {
      return {
        ...state,
        selectedRows: action.payload,
        selectedAllPages: false,
      };
    },
    toggleSelectedAllPages: (state, action) => {
      return {
        ...state,
        selectedRows: state.data.map((el: any) => el[action.payload.rowKey]),
        selectedAllPages: !state.selectedAllPages,
      };
    },
    setApiParamsHandler: (state, action) => {
      return {
        ...state,
        apiParamsHandler: action.payload,
      };
    },
    clearData: (state) => {
      return {
        ...state,
        data: [],
        loading: false,
        error: null,
        pagination: state.pagination
          ? {
              current: 1,
              total: 0,
              pageSize: state.pagination.pageSize,
            }
          : undefined,
        selectedRows: [],
        selectedAllPages: false,
      };
    },
    restoreInitialState: () => {
      return cloneDeep(tableInitialState);
    },
  };

  return createSlice({
    name,
    initialState: () => cloneDeep(tableInitialState),
    reducers: { ...reducers, ...extraReducers },
  });
}

export function selectApiParams(state: AppTableInitialState) {
  const params: Record<string, string | number | boolean | (string | number | boolean)[]> = state.pagination
    ? {
        'page[size]': state.serverControl ? state.pagination.pageSize : 1000,
        'page[number]': state.serverControl ? state.pagination.current : 1,
      }
    : {};

  if (state.serverControl && state.params.sort) {
    params['sort[by]'] = state.params.sort.column;
    params['sort[direction]'] = state.params.sort.order === 'ascend' ? 'asc' : 'desc';
  }

  if (state.params.search) {
    if (state.searchField) {
      params[`${state.searchKey}[field]`] = state.searchField;
      params[`${state.searchKey}[rule]`] = 'contains';
      params[`${state.searchKey}[value]`] = state.params.search;
    } else {
      params.search_term = state.params.search;
    }
  }

  Object.keys(state.params.filters).forEach((key) => {
    const filter = state.params.filters[key];

    const value = Array.isArray(filter) ? filter.map((x) => x.value) : filter.value;

    const searchFilter = state.searchFilters[key];

    if (searchFilter) {
      params[`search_term[0][${searchFilter.index}][rule]`] = searchFilter.rule;
      params[`search_term[0][${searchFilter.index}][field]`] = searchFilter.field;
      params[`search_term[0][${searchFilter.index}][value]`] = value;
      return;
    }

    const paramKey = state.paramFilterFields[key] ?? `filters[${key}]`;

    const currentValue = params[paramKey];

    params[paramKey] = currentValue
      ? [...(Array.isArray(currentValue) ? currentValue : [currentValue]), ...(Array.isArray(value) ? value : [value])]
      : value;
  });

  return { ...(state.apiParamsHandler ? state.apiParamsHandler(params, state) : params), ...state.fixedParams };
}

export function createAppTableSaga(slice: AppTableSlice, customSagaListener?: () => Generator<StrictEffect>) {
  function* fetchSagaListener(action: { payload?: Record<string, string | number | (string | number)[]>; type: string }) {
    const store: AppTableInitialState = yield select((globalStore) => globalStore[slice.name] as AppTableInitialState);

    if (store.ignoreFetch) return;

    try {
      yield put(slice.actions.setLoading(true));

      const defaultParams = selectApiParams(store);

      const res: AxiosResponse = yield call(get, store.url, {
        ...defaultParams,
        ...(action.payload ?? {}),
      });

      yield put(slice.actions.handleApiResponse(res.data));
    } catch (error) {
      yield put(slice.actions.setError(error));
      asyncErrorHandler(error);
    }
  }

  function* searchSagaListener() {
    yield put(
      slice.actions.fetch({
        'page[number]': 1,
      }),
    );
  }

  function* sortSagaListener() {
    const serverControl: boolean = yield select((store) => (store[slice.name] as AppTableInitialState).serverControl);

    if (serverControl) {
      yield put(slice.actions.fetch());
    }
  }

  function* paginationSagaListener() {
    const serverControl: boolean = yield select((store) => (store[slice.name] as AppTableInitialState).serverControl);

    if (serverControl) {
      yield put(slice.actions.fetch());
    }
  }

  function* filterSagaListener() {
    yield put(
      slice.actions.fetch({
        'page[number]': 1,
      }),
    );
  }

  function* updateRowSagaListener({ payload }: { payload: { uuid: string; data: Record<string, unknown> }; type: string }) {
    const url: string = yield select((store) => {
      const state = store[slice.name] as AppTableInitialState;

      return state.updateUrl ?? state.url;
    });

    try {
      yield putApi(`${url}/${payload.uuid}`, payload.data);
    } catch (error) {
      asyncErrorHandler(error);
    }
  }

  function* resetFilterSagaListener() {
    yield put(
      slice.actions.fetch({
        'page[number]': 1,
      }),
    );
  }

  return function* appTableSaga(): Generator<StrictEffect> {
    yield takeLatest(slice.actions.fetch.type, fetchSagaListener);
    yield takeLatest(slice.actions.setSearch.type, searchSagaListener);
    yield takeLatest(slice.actions.setSort.type, sortSagaListener);
    yield takeLatest(slice.actions.setPagination.type, paginationSagaListener);
    yield takeLatest(slice.actions.setFilter.type, filterSagaListener);
    yield takeLatest(slice.actions.resetFilters.type, resetFilterSagaListener);
    yield takeLatest(slice.actions.updateRow.type, updateRowSagaListener);

    if (customSagaListener) {
      const int = customSagaListener();

      while (true) {
        const res = int.next();

        if (res.done) break;

        yield res.value;
      }
    }
  };
}

export function createPersist<T>(slice: Slice<T>, whitelist: string[] = ['pagination']) {
  const persistConfig = {
    key: slice.name,
    storage,
    whitelist: ['params', ...whitelist],
  };

  return persistReducer(persistConfig, slice.reducer);
}
