import { put, takeLatest } from 'redux-saga/effects';
import { createAppTableSaga, createAppTableStore } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';

export const projectTimelogReportSlice = createAppTableStore({
  name: 'projectTimelogReport' as const,
  url: apiRoutes.TIME_LOG_REPORT,
  initialState: {
    fixedParams: { 'filters[status][]': 'completed' },
    serverControl: false,
    paramFilterFields: {
      project: 'filters[project_uuid][]',
      user: 'filters[user_uuid][]',
      date_start: 'date_from',
      date_end: 'date_to',
    },
    totalTime: null as null | string,
  },
  reducers: {
    setTotalTime: (state, { payload }: { payload: string; type: string }) => {
      return {
        ...state,
        totalTime: payload,
      };
    },
  },
});

function* handleApiResponseSagaListener({ payload }: { payload: any }) {
  yield put(projectTimelogReportSlice.actions.setTotalTime(payload.total));
}

export const projectTimelogReportSaga = createAppTableSaga(projectTimelogReportSlice, function* customGenerator() {
  yield takeLatest(projectTimelogReportSlice.actions.handleApiResponse, handleApiResponseSagaListener);
});

export const projectTimelogReportReducer = projectTimelogReportSlice.reducer;
