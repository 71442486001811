import { FC } from 'react';
import { Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { convertMinsToHrsMins } from 'utils/string.utils';
import { useHistory } from 'react-router';
import appRoutes from 'config/appRoutes';

import useWeeklyTimer from './useWeeklyTimer';

interface WeeklyTimerProps {}

const WeeklyTimer: FC<WeeklyTimerProps> = () => {
  const { state } = useWeeklyTimer();
  const history = useHistory();
  const { totalTimer, isBelow, loading, todayTimer } = state;
  if (!totalTimer) {
    return null;
  }

  const formatTime = (value: string) => {
    if (!value) return '';

    const hours = parseInt(value.split('h')[0], 10);
    const mins = parseInt(value.split('h')[1].replaceAll('m', ''), 10);
    return `${convertMinsToHrsMins(hours * 60 + mins)}`;
  };

  return (
    <div
      onClick={() => {
        history.push(`${appRoutes.TIME_LOG_REPORT}?current_week=true`);
      }}
      style={{ cursor: 'pointer' }}
    >
      <Space>
        <div className="mb-0" style={{ lineHeight: '20px', fontSize: 14 }}>
          <p style={{ color: 'white', marginBottom: 0, whiteSpace: 'nowrap' }}>T: {formatTime(todayTimer)}</p>
          <p style={{ color: isBelow ? '#FF6672' : 'white', marginBottom: 0, whiteSpace: 'nowrap' }}>
            W: {formatTime(totalTimer)}
          </p>
        </div>
        {loading && <LoadingOutlined style={{ color: 'white' }} spin />}
      </Space>
    </div>
  );
};

export default WeeklyTimer;
