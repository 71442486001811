import { useState } from 'react';
import { useSelector } from 'react-redux';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';

const useCommentReactions = (initialData: any, onReactAdd: any, onReactRemove: any) => {
  const user = useSelector((store: any) => store.auth.user);
  const currentUserReaction = initialData?.reactions?.find((el: any) => el?.creator?.uuid === user?.uuid);
  const [state, setState] = useState({ reacted: !!currentUserReaction, loading: false, reactionData: currentUserReaction });
  const { reacted, reactionData, loading } = state;

  const toggleReact = async () => {
    if (loading) {
      return;
    }

    try {
      const isReacted = !reacted;
      setState((prevState) => ({ ...prevState, reacted: isReacted, loading: true }));

      if (isReacted) {
        const res = await apiRequests.post(`${apiRoutes.COMMENTS}/${initialData?.uuid}/reaction`, {
          reaction: 'thumbs_up',
        });
        setState((prevState) => ({ ...prevState, loading: false, reactionData: res?.data?.data }));
        onReactAdd(res?.data?.data);
      } else {
        await apiRequests.delete(`${apiRoutes.REACTION}/${reactionData?.uuid}`);
        setState((prevState) => ({ ...prevState, loading: false, reactionData: null }));
        onReactRemove(reactionData);
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, reacted: !prevState.reacted }));
    }
  };

  return {
    state,
    toggleReact,
  };
};

export default useCommentReactions;
