import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Input, Space } from 'antd';
import { CloseCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';
import SimpleBar from 'simplebar-react';
import { CommentTemplate } from '../../types';
import TemplateCommentCard from './TemplateCommentCard';

interface TemplateCommentSearchProps {
  onTemplateClick?: (template: CommentTemplate) => void;
}

const TemplateCommentSearch: FC<TemplateCommentSearchProps> = ({ onTemplateClick }) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<CommentTemplate[]>([]);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const abort = new AbortController();

    const fetchTemplates = async () => {
      setLoading(true);

      try {
        const res = await apiRequests.get(
          `${apiRoutes.COMMENT_TEMPLATES}`,
          {
            search_term: search,
          },
          {},
          abort,
        );

        setTemplates(res.data.data);
        setLoading(false);
      } catch (error) {
        if (error.code === 'ERR_CANCELED') return;

        setLoading(false);

        asyncErrorHandler(error);
      }
    };

    fetchTemplates();

    return () => {
      abort.abort();
    };
  }, [search]);

  return (
    <div style={{ width: '100%', display: 'inline-flex', gap: '8px', alignItems: 'center' }}>
      <div style={{ marginBottom: '12px' }}>Templates: </div>
      <Input
        placeholder="Type to search"
        value={search}
        onChange={onChangeHandler}
        style={{ minWidth: 150, width: 150, marginBottom: '12px' }}
        suffix={
          <Space>
            <Loading3QuartersOutlined style={{ visibility: loading ? 'visible' : 'hidden' }} spin size={20} />
            {search && <CloseCircleOutlined className="pointer" onClick={() => setSearch('')} />}
          </Space>
        }
      />
      <SimpleBar autoHide={false} className="bar-custom" style={{ maxWidth: 'calc(100% - 240px)' }}>
        <Space style={{ paddingBottom: '12px' }}>
          {templates.map((template) => (
            <TemplateCommentCard key={template.uuid} template={template} onClick={onTemplateClick} />
          ))}
        </Space>
      </SimpleBar>
    </div>
  );
};

export default TemplateCommentSearch;
