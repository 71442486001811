import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import {
  getProjectTypeSelect,
  setProjectTypeSelectLoading,
  setProjectTypeSelectData,
  setProjectTypeSelectError,
} from './projectTypeSelect-slice';

const { get } = api;

function* getProjectSelectSagaListener(action: any) {
  try {
    yield put(setProjectTypeSelectLoading());

    const res: AxiosResponse = yield call(get, apiRoutes.PRODUCT_TYPES, {
      'page[size]': 999,
      'sort[by]': 'name',
      ...(action.payload ?? {}),
    });

    const data = res.data.data.map((item: any) => ({
      label: item.name,
      value: item.uuid,
    }));

    yield put(setProjectTypeSelectData(data));
  } catch (error) {
    yield put(setProjectTypeSelectError(error));
    asyncErrorHandler(error);
  }
}

function* projectSelectSaga(): Generator<StrictEffect> {
  yield takeLatest(getProjectTypeSelect.type, getProjectSelectSagaListener);
}

export default projectSelectSaga;
