import { useEffect, useRef, useState } from 'react';
import { Button, notification, Space } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { isSupported } from 'firebase/messaging';
import type { RootState } from 'store';
import { registerPushToken } from 'store/container/auth/auth-slice';
import { requestToken } from 'utils/firebase';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';

const PushNotification = () => {
  const [token, setToken] = useState<string | null>(null);
  const [isFcmSupported, setIsFcmSupported] = useState(false);
  const isTokenRequestedRef = useRef(false);
  const isPermissionRequestedRef = useRef(false);
  const isSupportedRequestedRef = useRef(false);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const { user, loading } = useSelector((globalState: RootState) => globalState.auth);

  const pushNotifications = user?.preferences?.push_notifications;

  const { uuid, push_tokens: pushTokens } = user ?? {};

  if (!isSupportedRequestedRef.current) {
    isSupportedRequestedRef.current = true;

    isSupported()
      .then((value) => setIsFcmSupported(value))
      .catch(() => {});
  }

  if (isFcmSupported && !token && !isPermissionRequestedRef.current && Notification.permission === 'granted') {
    isPermissionRequestedRef.current = true;

    requestToken().then((value) => {
      setToken(value);
    });
  }

  useEffect(() => {
    const userTokens = pushTokens ?? [];

    if (token && uuid && !userTokens.find((item) => item.token === token)) {
      dispatch(registerPushToken(token));
    }
  }, [dispatch, token, uuid, pushTokens]);

  useEffect(() => {
    if (!uuid && token && !loading) {
      apiRequests.delete(`${apiRoutes.FCM_TOKEN}/${token}`);
    }
  }, [loading, token, uuid]);

  useEffect(() => {
    if (pushNotifications === '0') {
      notification.close('request_notification_permission');
    }
  }, [api, pushNotifications]);

  useEffect(() => {
    if (
      isFcmSupported &&
      !token &&
      Notification.permission === 'default' &&
      pushNotifications === '1' &&
      localStorage.getItem('showNotificationPermission') !== '0'
    ) {
      api.open({
        key: 'request_notification_permission',
        icon: <LockOutlined style={{ color: '#c4bc35', fontSize: 28 }} />,
        message: 'Notification permission',
        description: (
          <Space size={12} direction="vertical">
            <p>Allow our alerts so you can stay up to date with all your tasks and projects.</p>
            <div className="w-full space-x-2 text-right">
              <Button
                type="link"
                onClick={() => {
                  notification.close('request_notification_permission');
                  localStorage.setItem('showNotificationPermission', '0');
                }}
              >
                No thanks
              </Button>
              <Button
                type="primary"
                onClick={async () => {
                  await Notification.requestPermission();

                  if (!isTokenRequestedRef.current) {
                    isTokenRequestedRef.current = true;

                    notification.close('request_notification_permission');

                    const newToken = await requestToken();

                    setToken(newToken);
                  }
                }}
              >
                Allow
              </Button>
            </div>
          </Space>
        ),
        duration: 0,
        closeIcon: false,
      });
    }
  }, [api, isFcmSupported, token, pushNotifications]);

  return contextHolder;
};

export default PushNotification;
