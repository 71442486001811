import { createSlice } from '@reduxjs/toolkit';

export const dashboardDueTodaySlice = createSlice({
  name: 'dashboardFailedPayments',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getDashboardFailedPayments: () => {},
    setDashboardFailedPaymentsLoading: (state) => ({ ...state, loading: true }),
    setDashboardFailedPaymentsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setDashboardFailedPaymentsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const {
  setDashboardFailedPaymentsData,
  setDashboardFailedPaymentsError,
  setDashboardFailedPaymentsLoading,
  getDashboardFailedPayments,
} = dashboardDueTodaySlice.actions;

export default dashboardDueTodaySlice.reducer;
