import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null as null | { value: string; label: string }[],
  error: null,
};

export const projectTypeSelectSlice = createSlice({
  name: 'projectTypeSelect',
  initialState,
  reducers: {
    getProjectTypeSelect: () => {},
    setProjectTypeSelectLoading: (state) => ({ ...state, loading: true }),
    setProjectTypeSelectData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setProjectTypeSelectError: (state, action) => ({ ...state, loading: false, error: action.payload }),
    clearProjectTypeSelectData: () => initialState,
  },
});

export const {
  setProjectTypeSelectData,
  setProjectTypeSelectError,
  setProjectTypeSelectLoading,
  getProjectTypeSelect,
  clearProjectTypeSelectData,
} = projectTypeSelectSlice.actions;

export default projectTypeSelectSlice.reducer;
