import { createSlice } from '@reduxjs/toolkit';

export const dashboardProjectsSlice = createSlice({
  name: 'dashboardProjects',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getDashboardProjects: () => {},
    setDashboardProjectsLoading: (state) => ({ ...state, loading: true }),
    setDashboardProjectsData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setDashboardProjectsError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setDashboardProjectsData, setDashboardProjectsError, setDashboardProjectsLoading, getDashboardProjects } =
  dashboardProjectsSlice.actions;

export default dashboardProjectsSlice.reducer;
