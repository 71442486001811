import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Dropdown, Menu, Button } from 'antd';
import { LogoutOutlined, ProfileOutlined } from '@ant-design/icons';
import UserAvatar from 'components/UserAvatar';
import appRoutes from 'config/appRoutes';
import { logout } from 'store/container/auth/auth-slice';
import style from './index.module.less';

interface UserDropdownProps {
  user: any;
}

const UserDropdown: FC<UserDropdownProps> = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Dropdown
      placement="bottomRight"
      trigger={[isMobile ? 'click' : 'hover']}
      overlayClassName={style.overlay}
      overlay={
        <Menu className={style.menu}>
          <Menu.Item
            key="profile"
            onClick={() => {
              history.push(`${appRoutes.CONTACTS}/${user?.contact?.uuid}/edit`);
            }}
          >
            <ProfileOutlined style={{ marginRight: 12 }} />
            Profile
          </Menu.Item>

          <Menu.Item
            key="logout"
            onClick={(event) => {
              event.domEvent.stopPropagation();
              event.domEvent.preventDefault();
              dispatch(
                logout({
                  onLoaded: () => history.push(appRoutes.LOGIN),
                }),
              );
            }}
          >
            <LogoutOutlined style={{ marginRight: 12 }} />
            Logout
          </Menu.Item>
        </Menu>
      }
    >
      <Button type="link" size="large" style={{ padding: 0 }}>
        <UserAvatar size="large" />
      </Button>
    </Dropdown>
  );
};

export default UserDropdown;
