import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import { getProjectSelect, setProjectSelectData, setProjectSelectError, setProjectSelectLoading } from './projectSelect-slice';

const { get } = api;

function* getProjectSelectSagaListener(action: any) {
  try {
    yield put(setProjectSelectLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PROJECTS_LITE, {
      'page[size]': 999,
      with_personal: true,
      ...(action.payload ?? {}),
    });
    yield put(setProjectSelectData(res.data));
  } catch (error) {
    yield put(setProjectSelectError(error));
    asyncErrorHandler(error);
  }
}

function* projectSelectSaga(): Generator<StrictEffect> {
  yield takeLatest(getProjectSelect.type, getProjectSelectSagaListener);
}

export default projectSelectSaga;
