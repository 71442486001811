import QuillCursors from 'quill-cursors';

export default class CustomCursor extends QuillCursors {
  quill: any;

  constructor(quill: any, options: any) {
    super(quill, options);
    this.quill = quill;
  }
}
