/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkIsSuperUser } from 'config/permissions';
import apiRoutes from 'config/apiRoute';
import apiRequests from 'utils/api';
import { checkIsAdmin } from 'utils/auth';

const useSidebar = () => {
  const user = useSelector((store: any) => store.auth.user);
  const isAdmin = checkIsAdmin(user);
  const isSuperUser = checkIsSuperUser(user?.email);

  const [showAnalytic, setShowAnalytic] = useState(isAdmin);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await apiRequests.get(`${apiRoutes.PROJECTS}`, {
          'page[size]': 1,
          'page[number]': 1,
          'filters[projects.status]': ['paused', 'waiting_to_launch', 'in_progress', 'active'],
          'filters[advertising][]': true,
        });

        if (res.data?.data?.length > 0) {
          setShowAnalytic(true);
        }
      } catch (error) {
        // PASS
      }
    };

    if (!isAdmin) {
      fetchData();
    }
  }, []);

  return {
    showAnalytic,
    user,
    isAdmin,
    isSuperUser,
  };
};

export default useSidebar;
