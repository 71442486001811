/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const taskCategorySlice = createSlice({
  name: 'taskCategory',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    getTaskCategory: (payload?: any) => {},
    setTaskCategoryLoading: (state) => ({ ...state, loading: true }),
    setTaskCategoryData: (state, action) => ({ ...state, loading: false, data: action.payload }),
    setTaskCategoryError: (state, action) => ({ ...state, loading: false, error: action.payload }),
  },
});

export const { setTaskCategoryData, setTaskCategoryError, setTaskCategoryLoading, getTaskCategory } = taskCategorySlice.actions;

export default taskCategorySlice.reducer;
