import { Context, FC, useContext, useEffect } from 'react';
import InfiniteScrollApi from 'components/InfiniteScrollApi';
import { InfinityScrollContext } from 'components/InfiniteScrollApi/createInfinityScrollApiContext';
import DropdownCard, { Notification } from './DropdownCard';
import styles from './index.module.less';

interface InfinityScrollProps {
  context: Context<InfinityScrollContext<Notification>>;
  onMarkAsRead: (item: Notification) => void;
  onMarkAsUnread: (item: Notification) => void;
  onDelete: (item: Notification) => void;
}

const DropdownBody: FC<InfinityScrollProps> = ({ context, onMarkAsRead, onMarkAsUnread, onDelete }) => {
  const { items, initialFetch, fetchMoreItems } = useContext(context);

  useEffect(() => {
    if (!initialFetch) {
      fetchMoreItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetch]);

  return (
    <div id="bell-notification--dropdown-body" className={styles.dropdownBody}>
      <InfiniteScrollApi scrollableTarget="bell-notification--dropdown-body" context={context}>
        {items.map((item) => (
          <DropdownCard
            key={item.id}
            notification={item}
            onMarkAsRead={onMarkAsRead}
            onMarkAsUnread={onMarkAsUnread}
            onDelete={onDelete}
          />
        ))}
      </InfiniteScrollApi>
    </div>
  );
};

export default DropdownBody;
