import { AxiosResponse } from 'axios';
import apiRoutes from 'config/apiRoute';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import asyncErrorHandler from 'utils/asyncErrorHandler';

import {
  getCommentTemplates,
  setCommentTemplatesData,
  setCommentTemplatesError,
  setCommentTemplatesLoading,
} from './comment-templates-slice';

const { get } = api;

function* getCustomersSagaListener(action: any) {
  try {
    yield put(setCommentTemplatesLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.COMMENT_TEMPLATES, action.payload);
    yield put(setCommentTemplatesData(res.data));
  } catch (error) {
    yield put(setCommentTemplatesError(error));
    asyncErrorHandler(error);
  }
}

function* commentTemplateSaga(): Generator<StrictEffect> {
  yield takeEvery(getCommentTemplates.type, getCustomersSagaListener);
}

export default commentTemplateSaga;
