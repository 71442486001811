import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveLogs } from 'store/container/activeLogs/activeLogs-slice';

const useHeader = () => {
  const user = useSelector((store: any) => store.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveLogs());
  }, [dispatch]);

  return {
    user,
  };
};

export default useHeader;
