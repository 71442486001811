import { RefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Y from 'yjs';
import type ReactQuill from 'react-quill';
import { QuillBinding } from 'y-quill';
import { WebrtcProvider } from 'y-webrtc';
import { fromUint8Array, toUint8Array } from 'js-base64';
import { debounceAsync } from 'utils/debounce';
import asyncErrorHandler from 'utils/asyncErrorHandler';
import ENVIRONMENT from 'config/environment';
import type { RootState } from 'store';
import type { CollaborativeEditorOptions } from 'types/richTextEditor';
import { useAwareness } from './useAwareness';

interface CollaborativeEditorParams {
  collaborativeEditor?: CollaborativeEditorOptions;
  quillRef: RefObject<ReactQuill>;
  initialValue?: string;
}

interface PromiseRef {
  promise?: Promise<any>;
}

type YDocUpdate = (update: any, source: any) => void;
type DebounceYDocUpdated = (
  doc: Y.Doc | undefined,
  callback: CollaborativeEditorOptions['onYDocUpdated'],
  promiseRef: PromiseRef,
) => Promise<void>;

export function useCollaborativeEditor({ collaborativeEditor, quillRef, initialValue }: CollaborativeEditorParams) {
  const user = useSelector((store: RootState) => store.auth.user);
  const initialValueRef = useRef(initialValue);
  const [yProvider, setYProvider] = useState<WebrtcProvider>();
  const [quillBinding, setQuillBinding] = useState<QuillBinding>();
  const yDocRef = useRef<Y.Doc>();
  const promiseYDocUpdateRef = useRef<Promise<any>>();
  const isSavingDocUpdateRef = useRef(false);
  const debounceYDocUpdatedRef = useRef<DebounceYDocUpdated>();
  const yDocUpdateRef = useRef<YDocUpdate>(() => {});

  const onStatusChanged = collaborativeEditor?.onStatusChanged;

  const { bindAwareness } = useAwareness({ yProvider, collaborativeEditor, user });

  if (initialValueRef.current !== initialValue) {
    initialValueRef.current = initialValue;
  }

  if (!debounceYDocUpdatedRef.current && collaborativeEditor?.onYDocUpdated) {
    debounceYDocUpdatedRef.current = debounceAsync<void, DebounceYDocUpdated>(async (doc, callback, promiseRef) => {
      if (!doc || !callback) return;

      const doc64 = fromUint8Array(Y.encodeStateAsUpdate(doc));

      try {
        isSavingDocUpdateRef.current = true;
        await callback(doc64);

        if (promiseRef.promise === promiseYDocUpdateRef.current) {
          collaborativeEditor.onStatusChanged?.('saved');
        }
      } catch (error) {
        collaborativeEditor.onStatusChanged?.('error saving');
        asyncErrorHandler(error);
      } finally {
        isSavingDocUpdateRef.current = false;
      }
    }, 3000);
  }

  yDocUpdateRef.current = (_update, source) => {
    if ((!(source instanceof QuillBinding) && !isSavingDocUpdateRef.current) || !collaborativeEditor) {
      return;
    }

    if (collaborativeEditor.status !== 'saving') {
      collaborativeEditor.onStatusChanged?.('saving');
    }

    isSavingDocUpdateRef.current = false;

    const promiseRef: PromiseRef = {
      promise: undefined,
    };

    promiseYDocUpdateRef.current = debounceYDocUpdatedRef.current?.(
      yProvider?.doc,
      collaborativeEditor.onYDocUpdated,
      promiseRef,
    );

    promiseRef.promise = promiseYDocUpdateRef.current;
  };

  useEffect(() => {
    if (collaborativeEditor?.useYDoc || collaborativeEditor?.room) {
      yDocRef.current = new Y.Doc();
    }

    if (collaborativeEditor?.room && yDocRef.current) {
      const provider = new WebrtcProvider(collaborativeEditor.room, yDocRef.current, {
        signaling: [ENVIRONMENT.REACT_APP_YJS_WEBSOCKET],
      });

      if (initialValueRef.current) {
        Y.applyUpdate(yDocRef.current, toUint8Array(initialValueRef.current));
      }

      provider.signalingConns.forEach((conn) => {
        conn.on('connect', ({ auth }: any) => {
          if (!auth) {
            conn.send({ type: 'auth', token: user?.auth_token });
          }
        });

        conn.on('disconnect', () => {
          onStatusChanged?.('disconnected');
        });

        conn.on('message', (message: any) => {
          if (message.type === 'auth') {
            if (!message.auth) {
              provider.disconnect();
              return;
            }

            onStatusChanged?.((old) => (old === 'saving' ? 'saving' : 'saved'));

            if (conn.connected) conn.emit('connect', [{ type: 'connect', auth: true }]);
          }
        });
      });

      yDocRef.current.on('update', (update: any, source: any) => {
        yDocUpdateRef.current(update, source);
      });

      setYProvider(provider);

      return () => {
        provider.disconnect();
        provider.destroy();
        yDocRef.current?.destroy();
      };
    }

    return () => {};
  }, [onStatusChanged, user?.auth_token, collaborativeEditor?.room, collaborativeEditor?.useYDoc]);

  useEffect(() => {
    if (quillRef.current && yDocRef.current) {
      const yText = yDocRef.current.getText('quill');

      const editor = quillRef.current.getEditor();

      const binding = new QuillBinding(yText, editor, yProvider?.awareness);

      setQuillBinding(binding);

      const clearAwarenessEvents = bindAwareness(yText, editor);

      return () => {
        clearAwarenessEvents();
        binding.destroy();
      };
    }

    return () => {};
  }, [yProvider, bindAwareness, quillRef]);

  useEffect(() => {
    if (yProvider && quillBinding && initialValue) {
      Y.applyUpdate(yProvider.doc, toUint8Array(initialValue));
      yDocUpdateRef.current(null, quillBinding);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return {
    yProvider,
    yDoc: yDocRef.current,
  };
}
