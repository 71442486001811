import { PermissionKey } from 'config/permissions';
import { useSelector } from 'react-redux';
import { checkHasPermission } from './auth';
import { UserResponse } from '../types';

const usePermissions = <T extends PermissionKey[]>(keyPermissions: T): Record<T[number], boolean> => {
  const user: UserResponse = useSelector((store: any) => store.auth.user);

  const canPermission: Record<string, boolean> = {};

  for (let x = 0; x < keyPermissions.length; x++) {
    canPermission[keyPermissions[x]] = checkHasPermission(user, keyPermissions[x]);
  }

  return canPermission;
};

export default usePermissions;
